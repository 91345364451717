import React from 'react'
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel'


export default function RadioMaterial(props) {
      
    return (
        <FormControl 
            disabled={props.disabled} 
            component="fieldset"
            className={props.className}
            
        >
            <FormLabel 
                style={props.styleLabel}
                component="legend"
            >
                {props.label}
            </FormLabel>
            <RadioGroup 
                style={{color: '#FFFF'}}
                row 
                aria-label={props.ariaLabel} 
                name={props.name} 
                value={props.value} 
                onChange={props.onChange}
            >
                {props.children}
            </RadioGroup>
        </FormControl>
    )
}
