import React from 'react'

export default function StylePage(props) {
    return (
        <div className="content-wrapper" style={{background: '#262D40'}}>
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-12">
                            <h1 style={{color: 'rgb(0, 171, 255)', textAlign: 'center'}}>{props.title}</h1>
                            
                        </div>
                    </div>
                </div>{/* /.container-fluid */}
            </section>

            {/* Main content */}
            <section className="content" style={{background: '#262D40'}}>

                

                    <div className="row">
                        <div className="col-12">
                            <div className="card" style={{background: 'linear-gradient(180deg,#1D3A59 0%,#0A1D3A 100%)'}}   >
                                {/* <div className="card-header">
                                    <h3 className="card-title">{props.subtitle}</h3>
                                    <div className="text-right">
                                        {props.button || ''}
                                    </div>
                                </div> */}
                                {props.children}
                            </div>
                        </div>
                    </div>
                
            </section>
            
        </div>
    )
}
