import React, { useEffect } from 'react';

// import { Container } from './styles';

function Suport() {
    useEffect(() => {
        redirect()
        
    }, [])

    function redirect(){
        return window.location.href = "https://forms.gle/XkofjGLHaDZrSK3i6";
    }
  return <div />;
}

export default Suport;