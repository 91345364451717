import { app } from './Api'

const getPerfis = async () => {
    return app.get(`get_resource_user`)
}

const get_view_user = async (id) => {
    return app.get(`user/${id}`)
}

const register = async (data) => {
    return app.post("user",data);
}

const get_users = async (page,qtd_per_page,order,th,pesquisa) => {
    return app.get(`user?page=${page}&qtd=${qtd_per_page}&order=${order}&th=${th}&pesquisa=${pesquisa}`)
}

const del_user = async (id) => {
    return app.delete(`user/${id}`)
}

const alter_password = async (id,data) => {
    return app.put(`alter_password/${id}`,data)
}

const edit_user = async (id,data) => {
    return app.put(`user/${id}`,data)
}

const alter_information = async (id,data) => {
    return app.post(`alter_information/${id}`, data)
}

const sendEmailPassword = async (data) => {
    return app.post(`password/email`, data)
}

const rememberPassword = async (data) => {
    return app.post(`password/reset`, data)
}

const changeStatus =  async (id,data) => {
    return app.put(`user/change_status/${id}`,data)
}

const getRelations = async() => {
    return app.get(`relation_user`)
}

const postRelationUser = async(data) => {
    return app.post(`post_relation_user`, data)
}

const getFarmsUser = async (page,qtd_per_page,order,th,pesquisa,idUser) => {
    return app.get(`get_farms_user?idUser=${idUser}&page=${page}&qtd=${qtd_per_page}&order=${order}&th=${th}&pesquisa=${pesquisa}`)
}

const delFarmUser = async (idUser,idFarm) => {
    return app.delete(`del_farm_user?idFarm=${idFarm}&idUser=${idUser}`)
}

export { getFarmsUser,postRelationUser, delFarmUser, register, getRelations, sendEmailPassword, changeStatus, edit_user, rememberPassword, alter_password, alter_information, getPerfis, get_view_user, get_users, del_user }