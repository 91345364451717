import React from 'react'
import { Link } from 'react-router-dom'
import "react-toggle/style.css"
import Toggle from 'react-toggle'
import Card from '../../../Card/Card'
import { ButtonAction } from '../../../Button/style'
import RecipeReviewCard from '../../../RecipeReviewCard/RecipeReviewCard'

export default function FarmsUsers(props) {
    const farmUser = props.farmUsers.data || []
    return farmUser.map((desc,i)=>(
        <div key={desc.id}  className="col-md-4 mt-4">
            <RecipeReviewCard
                title={desc.name}
                actionRoute={props.action}
                rel={false}
                actions={
                    props.action !== 'view' &&
                    <div className="text-center">
                        <ButtonAction>
                            <button type="button" onClick={(e) => props.history.push(`/farm/${desc.id}/view`)} className="btn"><i style={{color: '#FFFF', width:'30px'}} className='fas fa-eye'></i></button>
                            <button type="button" onClick={(e) => props.history.push(`/farm/${desc.id}/edit`)} className="btn" ><i style={{color: '#FFFF', width:'30px'}} className='fas fa-edit'></i> </button>
                            <button type="button" className="btn" onClick={()=>props.onDelete(desc.id)} ><i style={{color: '#FFFF', width:'1px'}} className='fas fa-trash-alt'></i> </button>
                        </ButtonAction>

                    </div>
                
                }
            >
                <p >{`Código: ${desc.code}`}</p>
                <p>
                    <span>
                        <a href="#/" onClick={(e) => {props.history.push(`/producer/${desc.id_producer}/view`);e.preventDefault()}}>{`Produtor: ${desc.producer}`}</a>
                    </span>

                </p>
                    
            </RecipeReviewCard>
        </div>
      
    ))
    
}
