import React, { useState, useEffect } from 'react'
import Header from '../../../Header/Header'
import Sidebar from '../../../Sidebar/Sidebar'
import StylePage from '../../../Style Page/StylePage'
import Form from '../../../Forms/Form'
import Input from '../../../Forms/Input'
import Alert from '../../../Alerts/Alert'
import Footer from '../../../Footer/Footer'
import ReactLoading from 'react-loading'
import { Content } from '../../../LoadingStyle/StyleLoading'
import NumberFormat from 'react-number-format'
import { Link, useHistory } from "react-router-dom"
import { useSelector } from 'react-redux'
import { postProducer, getViewProducer, putProducer, getResource } from '../../../Services/producer'
import '../../../../Dependencies/dependencies'
import '../../../../Dependencies/jquery-loader'
import InputMaterial from '../../../Forms/InputMaterial';
import SelectMaterial from '../../../Forms/SelectMaterial';
import RadioMaterial from '../../../Forms/RadioMaterial';
import { ButtonContainner } from '../../../Button/style';


export default function Producer(props) {

    let history = useHistory();
    const [ loading, setLoading ] = useState(false)
    const [ code, setCode ] = useState('')
    const [ name, setName ] = useState('')
    const [ landLine, setLandLine ] = useState('')
    const [ mobilePhone, setMobilePhone ] = useState('')
    const [ partners, setPartners ] = useState([{value: '', title: ''}])
    const [ idPartner, setIdPartner ] = useState({value: '', title: ''})
    const [ error, setError ] = useState([])
    const [ success, setSuccess ] = useState('')
    const [ alert, setAlert ] = useState('')
    
    useEffect(() => {
        viewPerId()
       
    }, [props.match.params.id])

    useEffect(() => {
        getPartnerAll()
       
    }, [])

    function viewPerId(){
        if(props.match.params.action){
            getViewProducer(props.match.params.id)
            .then(resp => {
                setName(resp.data.name)
                setCode(resp.data.code)
                setLandLine(resp.data.landLine)
                setMobilePhone(resp.data.mobilePhone)
                setIdPartner({value: resp.data.id_partner, title: resp.data.partner})

            })
        }
        
    }

    function getPartnerAll(){
        getResource()
        .then(resp => {
            setPartners(resp.data.map(desc => ({
                value: parseInt(desc.id),
                title: desc.name
            })))
        })
    }

    const changeName = event => {
        const name = event.target.value
        setName(name)
    }

    const changeLandLine = event => {
        const number = event.target.value.replace('(','').replace(')','').replace(' ','').replace('-','')
        setLandLine(number)
    }

    const changeMobilePhone = event => {
        const number = event.target.value.replace('(','').replace(')','').replace(' ','').replace('-','')
        setMobilePhone(number)
    }

    const defaultProps = {
        options: partners,
        getOptionLabel: (option) => option.title,
        value:(option) => option.value
    };

    const handleForm=(e)=>{
        e.preventDefault();
        setLoading(true)
        
        const data = {
            name: name,
            code: parseInt(code),
            mobilePhone: mobilePhone,
            landLine: landLine,
            idPartner: idPartner.value
        }

        if(props.match.params.action === 'edit'){
            const id = props.match.params.id
            putProducer(id,data)
            .then(resp=> {
                setLoading(false)
                setAlert('')
                setError([])
                setSuccess(resp.data.success)    
            })
            .then(resp => setTimeout(() => {
                window.location.reload()
            },2500))
            .catch(e => {
                setLoading(false)
                setSuccess('')
                setError(e.response.data.error)
                setAlert(e.response.data.errorSubmit)  
            })              


        }else{
            postProducer(data)
            .then(resp=> {
                setLoading(false)
                setAlert('')
                setError([])
                setSuccess(resp.data.success)    
            })
            .then(resp => setTimeout(() => {
                window.location.reload()
            },2500))
            .catch(e => {
                setLoading(false)
                setSuccess('')
                setError(e.response.data.error)
                setAlert(e.response.data.errorSubmit)  
            })              

        }
    }

    return (
        <div className="wrapper">
            <Sidebar />
            <Header />
            <StylePage>
                {loading === true ? (
                    <Content>
                        <ReactLoading type="spinningBubbles" color="blue" height={200} width={100} />
                    </Content>
                    
                ):('')}
                {alert ? (
                    <Alert type="danger">
                        {alert}
                    </Alert>
                ):('')}
                {success ? (
                    <Alert type="success">
                        {success}
                    </Alert>
                ):('')}
                <div style={{display: loading === true ? ('none'):('block')}}>
                    <section className="content-header">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <h2 style={{color: '#00ABFF', textAlign: 'center'}}>{props.match.params.action === 'edit' ? "Edição de Produtores" : props.match.params.action === 'view' ? "Visualização de Produtores" : "Cadastro de Produtores"}</h2>
                                </div>
                            </div>
                        </div>{/* /.container-fluid */}
                    </section>
                    <Form onSubmit={handleForm}>
                        <div className="row">
                            <div className="col-md-6">
                                <NumberFormat  
                                    label="Código" 
                                    name="codeProducer" 
                                    fixedDecimalScale={true}
                                    decimalScale={0}
                                    placeholder=""
                                    customInput={InputMaterial}
                                    allowNegative={false}
                                    onChange={(e)=>setCode(parseInt(e.target.value))} 
                                    value={code}
                                    disabled={props.match.params.action == 'view' ? true : false}
                                    required={true} 
                                    isAllowed={(values) => { const {floatValue,formattedValue} = values;  return formattedValue === "" || floatValue <= 99999999999;}}                         
                                />
                                {error ? (<p style={{color:'red'}}>{error.code}</p>):('')}
                            </div>
                            <div className="col-md-6">
                                <InputMaterial 
                                    label="Nome Completo" 
                                    value={name} 
                                    onChange={changeName} 
                                    id="nameProducer"
                                    name="nameProducer"
                                    type="text" 
                                    placeholder="Digite o nome do produtor" 
                                    required={true}
                                    disabled={props.match.params.action === 'view' ? true : false}
                                />
                                 {error ? (<p style={{color:'red'}}>{error.name}</p>):('')}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <NumberFormat 
                                    disabled={props.match.params.action === 'view' ? (true):(false)} 
                                    allowEmptyFormatting={false} 
                                    label="Telefone Fixo"  
                                    mask='' 
                                    required={true}
                                    customInput={InputMaterial} 
                                    onChange={changeLandLine}  
                                    format={"(##) ####-####"} 
                                    value={landLine}
                                    name="landLineProducer" 
                                    id="landLineProducer"
                                    type="text" 
                                    placeholder="Digite o telefone fixo do produtor" 
                                />
                                {error ? (<p style={{color:'red'}}>{error.landLine}</p>):('')} 
                            </div>
                            <div className="col-md-6">
                                <NumberFormat 
                                    disabled={props.match.params.action === 'view' ? (true):(false)} 
                                    allowEmptyFormatting={false} 
                                    label="Telefone Celular"  
                                    mask='' 
                                    customInput={InputMaterial} 
                                    onChange={changeMobilePhone}  
                                    format={"(##) #####-####"} 
                                    value={mobilePhone}
                                    name="mobilePhoneProducer" 
                                    id="mobilePhoneProducer"
                                    type="text" 
                                    placeholder="Digite o telefone celular do produtor" 
                                />
                                {error ? (<p style={{color:'red'}}>{error.mobilePhone}</p>):('')} 
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <SelectMaterial 
                                    label="Parceiro"
                                    id="idPartner"
                                    name="idPartner"
                                    value={idPartner}
                                    onChange={(event, newValue) => {
                                        setIdPartner(newValue);
                                    }}
                                    defaultProps={defaultProps}
                                    required={true}
                                    disabled={props.match.params.action === 'view' ? true : false}
                                />
                                {error ? (<p style={{color:'red'}}>{error.idPartner}</p>):('')} 
                            </div>
                        </div>
                        <br></br>
                        <div className="text-right">
                            <button style={{marginRight:'10px'}} type="button" className="btn btn-secondary" onClick={()=>history.goBack()}>Voltar</button>
                            {props.match.params.action === 'view' ? (
                                 <div style={{display: 'inline-block'}}>
                                    <ButtonContainner>
                                        <button onClick={(e) => {props.history.push(`/producer/${props.match.params.id}/edit`);e.preventDefault()}} className="btn btn-primary">Editar</button>
                                    </ButtonContainner>
                                </div>
                            ) : (
                                <div style={{display: 'inline-block'}}>
                                    <ButtonContainner>
                                        <button disabled={props.match.params.action === 'view' ? true : false} type="submit" className="btn btn-primary">Salvar</button>                           
                                    </ButtonContainner>
                                </div>
                            )}
                        </div>
                    </Form>
                </div>   
            </StylePage>
            <Footer />
        </div>
    )
}
