import { get_customers } from '../../../../Services/client'

//Função que traz os dados do banco de dados
export const get = () =>{
    return[(dispatch,getState)=>{
        
        const order = getState().client.order
        const th = getState().client.th
        const qtd_per_page = getState().client.qtd_per_page
        const pesquisa = getState().client.pesquisa || ''       
        const page = getState().client.page 

        get_customers(page,qtd_per_page,order,th,pesquisa)
        .then(resp=>dispatch({type:'Customers', payload:resp.data}))

    }]
}

//Função que ordena as colunas
export const order = (coluna) => {
    return [(dispatch,getState)=>{
        const asc = getState().client.asc
      
        if(asc === true){
            dispatch({type:'GetClient_Desc', desc:true, asc:false,order:'desc',th:coluna})
            
        }if(asc === false){
            dispatch({type:'GetClient_Asc', asc:true, desc:false,order:'asc',th:coluna})
            
        }
     
    },get(1)]    
}

export const change_qtd_per_page = event => {
    return[{
        type:'qtd_per_page_client',
        payload:event.target.value
    },get(1)]
}

export const changePesquisa = event => {
    return[{
        type:'pesquisaClient',
        payload:event.target.value
    }]
}

export const clearPesquisa = event => {
    return[{
        type:'clearPesquisaClient',
        payload:''
    },get(1)]
}

export const changePage = page => {
    return[{
        type: 'changePageClient',
        payload: page
    },get()]
}

export const search = () => {
    return [
        (dispatch, getState) => {
            const pesquisa = getState().client.pesquisa || ''
            if(pesquisa){
                return dispatch({type:'changePageClient', payload:1})
            }
        }, get()
    ]
}