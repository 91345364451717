import { app } from './Api'

const get_view_flow = async (id) => {
    return app.get(`flow/${id}`)
}

const get_flow = async (page,qtd_per_page,order,th,pesquisa) => {
    return app.get(`flow?page=${page}&qtd=${qtd_per_page}&order=${order}&th=${th}&pesquisa=${pesquisa}`)
}

const post_flow = async (data) => {
    return app.post(`flow`, data)
}

const changeStatus =  async (id,data) => {
    return app.put(`change_status_flow/${id}`,data)
}

const put_flow = async (id,data) => {
    return app.post(`flow/${id}`,data)
}

const del_flow = async (id) => {
    return app.delete(`flow/${id}`)
}


export { get_view_flow, get_flow, changeStatus, post_flow, put_flow, del_flow }