import React from 'react'
import "react-toggle/style.css"
import Toggle from 'react-toggle'
import Card from '../../../Card/Card'
import { ButtonAction } from '../../../Button/style'
import { colourOptions } from './Docs/colors'
import RecipeReviewCard from '../../../RecipeReviewCard/RecipeReviewCard'

export default function Flows(props) {
    const flow = props.flows || []
    
    return flow.map(desc=>(
        <div key={desc.id}  className="col-md-4 mt-4">
            <RecipeReviewCard
                title={desc.name}
                actionRoute={props.action}
                actionDisabled={
                    <Toggle
                        
                        id={`${desc.id}`}
                        checked={desc.active === 1 ?(true):(false)}
                        onChange={props.changeStatus} 
                    /> 
                }
                actions={
                    props.action !== 'view' &&
                    <div className="text-center">
                        <ButtonAction>
                            
                            <button type="button" onClick={(e) => props.history.push(`/flow/${desc.id}/view`)} className="btn"><i style={{color: '#FFFF', width:'30px'}} className='fas fa-eye'></i></button>
                            <button type="button" onClick={(e) => props.history.push(`/flow/${desc.id}/edit`)} className="btn" ><i style={{color: '#FFFF', width:'30px'}} className='fas fa-edit'></i> </button>
                            <button type="button" className="btn" onClick={()=>props.onDelete(desc.id)} ><i style={{color: '#FFFF', width:'1px'}} className='fas fa-trash-alt'></i> </button>
                        </ButtonAction>

                    </div>
                
                }
                rel={
                    desc.topics.map((res,i) => (
                        <p>
                            <span>
                                <a href="#/" onClick={(e) => {props.history.push(`/topic/${res.id}/view`);e.preventDefault()}}>{`Tópico ${i+1}: ${res.name}`}</a>
                            </span>

                        </p>
                    ))
                }
            >
            <p style={{color: desc.color}}>{`Cor: ${colourOptions.filter(resp => resp.id === desc.color)[0].title}`}</p>
                    
            </RecipeReviewCard>
        </div>


      

      
    ))
    
}
