import React from 'react'
import { Link } from 'react-router-dom'
import "react-toggle/style.css"
import Toggle from 'react-toggle'
import Card from '../../../Card/Card'
import { ButtonAction } from '../../../Button/style'
import RecipeReviewCard from '../../../RecipeReviewCard/RecipeReviewCard'

export default function FlowPacks(props) {
    const flowPack = props.flowPacks || []
    return flowPack.map(desc=>(

        <div key={desc.id} className="col-md-4 mt-4">
            <RecipeReviewCard
                title={desc.flow_pack}
                actionRoute={props.action}
                actionDisabled={
                    <Toggle
                        
                        id={`${desc.id}`}
                        checked={desc.active === 1 ?(true):(false)}
                        onChange={props.changeStatus} 
                    /> 
                }
                actions={
                    props.action !== 'view' &&
                    <div className="text-center">
                        <ButtonAction>
                            <button type="button" onClick={(e) => props.history.push(`/flow_pack/${desc.id}/view`)} className="btn"><i style={{color: '#FFFF', width:'30px'}} className='fas fa-eye'></i></button>
                            <button type="button" onClick={(e) => props.history.push(`/flow_pack/${desc.id}/edit`)} className="btn" ><i style={{color: '#FFFF', width:'30px'}} className='fas fa-edit'></i> </button>
                            <button type="button" className="btn" onClick={()=>props.onDelete(desc.id)} ><i style={{color: '#FFFF', width:'1px'}} className='fas fa-trash-alt'></i> </button>
                        </ButtonAction>

                    </div>
                
                }
                rel={false}
            >
                    
            </RecipeReviewCard>



           
        </div>

        // <tr key={desc.id}>
        //     <td>{desc.flow_pack}</td>
        //     <td>{desc.flow.split(",").map(resp => (
        //         <React.Fragment>
        //             {`-> ${resp}`}
        //             <br />
        //         </React.Fragment>
        //     ))}</td>
        //     <td>
        //         <ul className="nav">
        //             <li className="nav-item dropdown">
        //                 <a className="nav-link" data-toggle="dropdown" href="#/">
        //                     <i className="fas fa-ellipsis-h" />             
        //                 </a>
        //                 <div className='dropdown-menu dropdown-menu-right'>                  
        //                     <Link to={`/flow_pack/${desc.id}/view`} className='dropdown-item'><i className='fas fa-eye'></i> Detalhes</Link>
                           
        //                     <Link to={`/flow_pack/${desc.id}/edit`} className='dropdown-item' ><i className='fas fa-edit'></i> Editar</Link>
        //                     <Link to="#/" className='dropdown-item' onClick={()=>onDelete(desc.id)} ><i className='fas fa-trash-alt'></i> Excluir</Link>                                
                              
                            
        //                 </div>
        //             </li>
        //         </ul>		
        //     </td>                
        // </tr>
    ))
    
}
