import {Route, Redirect} from 'react-router-dom'
import React from 'react'
import {connect} from 'react-redux'

const AuthRouter = ({component:Component, ...rest }) =>{
   
    
    return (  
        <Route
            {...rest}
            
            render={props => {
                const token = localStorage.getItem('token')
                
                if(token){       
                    return <Component {...props} />
                }else{
                    return <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: props.location }
                        }}
                    />  

                }     
            }}
        />
    );
}
const mapStateToProps = state => {
    return{
        perfis:state.auth.user.perfis,   
    }
}
export default connect(mapStateToProps)(AuthRouter);