import styled from 'styled-components';

export const Container = styled.div`
    a:hover{
        background: linear-gradient(180deg, #00E6FF 0%, #0070BA 109.18%);
        color: '#FFF';
        p{
            color: white;
        }
        i{
            color: white;
        }
      
    }
    


`