import React, { useState, useEffect, useRef } from 'react'
import Header from '../../../Header/Header'
import Sidebar from '../../../Sidebar/Sidebar'
import StylePage from '../../../Style Page/StylePage'
import Form from '../../../Forms/Form'
import Alert from '../../../Alerts/Alert'
import Footer from '../../../Footer/Footer'
import ReactLoading from 'react-loading'
import MyModal from '../../../Modal/MyModal'
import { Content } from '../../../LoadingStyle/StyleLoading'
import { Link, useHistory } from "react-router-dom"
import { postLine, getViewLine, putLine, delFarmLine } from '../../../Services/line'
import { ButtonContainner } from '../../../Button/style'
import InputMaterial from '../../../Forms/InputMaterial'
import Farms from '../Farm/Farms'
import { delFarm } from '../../../Services/farm'
import FarmsLine from './FarmsLine'


export default function Line(props) {

    let history = useHistory();
    const [ loading, setLoading ] = useState(false)
    const [ modal, setModal ] = useState(false);
    const [ idFarm, setIdFarm ] = useState('');
    const [ name, setName ] = useState('')
    const [ error, setError ] = useState([])
    const [ farms, setFarms ] = useState([])
    const [ success, setSuccess ] = useState('')
    const [ alert, setAlert ] = useState('')
    const selectFlowRef = useRef(null)

    // useEffect(() => {
    //     getResource()
    // }, [])

    useEffect(() => {
        viewPerId()
    }, [])
  
    // function getResource(){
    //     get_resource()
    //     .then(resp => {
    //         setFlows(resp.data.map(desc => ({
    //             id: parseInt(desc.id),
    //             title: desc.name
    //         })))
    //     })
    // }

    function viewPerId(){
        props.match.params.action &&
        getViewLine(props.match.params.id)
        .then(resp => {
            setName(resp.data.line[0].name)
            setFarms(resp.data.farms)
            // setIdLineFlow({id: resp.data[0].id_flow, title: resp.data[0].flow})
        })
    }

    const onChangeModal = (idFarm) => {
        setModal(true);
        setIdFarm(idFarm);
        
    }

    const handleCloseExcluir = event => {
        setModal(false);
        setIdFarm('');
        
    }

    const handleExcluir = (e) => {
        e.preventDefault();
        setLoading(true);
        setModal(false)
        
        delFarmLine(props.match.params.id,idFarm)
        .then(resp => {
            setSuccess(resp.data.success)
            setError('')
            setLoading(false)
        
        })
        .then(resp => (
            setTimeout(()=> {
                setSuccess('')
            },4000)

        ))
        .catch(e => {
            setLoading(false)
            setError(e.response.data.error)
            setSuccess('')
            setModal(false)
            setTimeout(()=> {
                setError('')
            },4000)
        
        })
        .then(resp => (viewPerId()))
        // .then(resp => {setState({success:resp.data.success, error:''})})

    }

    const handleForm=(e)=>{
        e.preventDefault();
        setLoading(true)
        const data = {
            name: name,
        }

        if(props.match.params.action === 'edit'){
            const id = props.match.params.id
            putLine(id,data)
            .then(resp=> {
                setLoading(false)
                setAlert('')
                setError([])
                setSuccess(resp.data.success)    
            })
            .then(resp => setTimeout(() => {
                window.location.reload()
            },2500))
            .catch(e => {
                setLoading(false)
                setSuccess('')
                setError(e.response.data.error)
                setAlert(e.response.data.errorSubmit)  
            })              
        }else{
            postLine(data)
            .then(resp=> {
                setLoading(false)
                setAlert('')
                setError([])
                setSuccess(resp.data.success)    
            })
            .then(resp => setTimeout(() => {
                window.location.reload()
            },2500))
            .catch(e => {
                setLoading(false)
                setSuccess('')
                setError(e.response.data.error)
                setAlert(e.response.data.errorSubmit)  
            })              

        }
    }
    // const classes = useStyles();
    return (
        <div className="wrapper">
            <Sidebar />
            <Header />
            <StylePage>
                {loading === true ? (
                    <Content>
                        <ReactLoading type="spinningBubbles" color="blue" height={200} width={100} />
                    </Content>
                    
                ):('')}
                {alert ? (
                    <Alert type="danger">
                        {alert}
                    </Alert>
                ):('')}
                {success ? (
                    <Alert type="success">
                        {success}
                    </Alert>
                ):('')}
                <MyModal
                    show={modal}
                    onHide={handleCloseExcluir}
                    title="Excluir Fazenda"
                    content="Tem certeza que deseja excluir a fazenda da Linha ?"
                    cancel={handleCloseExcluir}
                    del={handleExcluir}     
                    to="#/"
                    type="danger"
                    textButton="Excluir"                   
                />
                <div style={{display: loading === true ? ('none'):('block')}}>
                    <section className="content-header">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <h2 style={{color: '#00ABFF', textAlign: 'center'}}>{props.match.params.action === 'edit' ? "Edição de Linhas" : props.match.params.action === 'view' ? "Visualização de Linhas" : "Cadastro de Linhas"}</h2>
                                </div>
                            </div>
                        </div>{/* /.container-fluid */}
                    </section>
                    <Form onSubmit={handleForm}>
                        <div className="row">
                            <div className='col-md-6 offset-md-3'>
                                <InputMaterial 
                                    label="Nome da Linha"
                                    id="nameLine"
                                    name="nameLine"
                                    value={name}
                                    onChange={(e)=>setName(e.target.value)} 
                                    required={true}
                                    disabled={props.match.params.action === 'view' ? true : false}
                                />
                                {error ? (<p style={{color:'red'}}>{error.name}</p>):('')}
                            </div>
                            {/* <div className="col-md-6 offset-md-3">
                                <Autocomplete
                                    {...defaultProps}
                                    value={idFlow}
                                    id="flow" 
                                    name="flow"
                                    onChange={(event, newValue) => {
                                        setIdLineFlow(newValue);
                                    }}
                                    disabled={props.match.params.action === 'view' ? true : false}
                                    renderInput={(params) => <TextField {...params} className={classes.margin} label="Fluxo" required={true} />}
                                />
                            </div> */}
                        </div>
                       
                        <br></br>
                        <div className="text-right">
                            <button style={{marginRight:'10px'}} type="button" className="btn btn-secondary" onClick={()=>history.goBack()}>Voltar</button>
                            {props.match.params.action === 'view' ? (
                                 <div style={{display: 'inline-block'}}>
                                    <ButtonContainner>
                                        <button onClick={(e) => {props.history.push(`/line/${props.match.params.id}/edit`);e.preventDefault()}} className="btn btn-primary">Editar</button>
                                    </ButtonContainner>
                                </div>
                            ) : (
                                <div style={{display: 'inline-block'}}>
                                    <ButtonContainner>
                                        <button disabled={props.match.params.action === 'view' ? true : false} type="submit" className="btn btn-primary">Salvar</button>                           
                                    </ButtonContainner>
                                </div>
                            )}
                        </div> 
                        <div className="row mt-4">
                            <FarmsLine farms={farms} history={props.history} onDelete={onChangeModal} action={props.match.params.action} />
                        </div>
                    </Form>
                </div>   
            </StylePage>
            <Footer />
        </div>
    )
}
