const FlowPackReducer = (state = {}, actions)=>{
    switch(actions.type){

        case 'FlowPacks':
            return{...state, flowPacks:actions.payload}

        case 'GetFlowPacks_Desc':
            return{...state, desc:actions.desc, asc:actions.asc, order:actions.order, th:actions.th}

        case 'GetFlowPacks_Asc':
            return{...state, desc:actions.desc, asc:actions.asc, order:actions.order, th:actions.th}

        case 'qtd_per_page_FlowPacks':
            return{...state, qtd_per_page:actions.payload}

        case 'pesquisaFlowPacks':
            return{...state, pesquisa:actions.payload}

        case 'clearPesquisaFlowPacks':
            return{...state, pesquisa:actions.payload}

        case 'changePageFlowPacks':
            return{...state, page:actions.payload}

        default:
            return state;
    }
}
export default FlowPackReducer;