import React, { useState, useEffect, useRef } from 'react'
import Header from '../../../Header/Header'
import Sidebar from '../../../Sidebar/Sidebar'
import StylePage from '../../../Style Page/StylePage'
import Form from '../../../Forms/Form'
import Input from '../../../Forms/Input'
import Alert from '../../../Alerts/Alert'
import Footer from '../../../Footer/Footer'
import ReactLoading from 'react-loading'
import { Content } from '../../../LoadingStyle/StyleLoading'
import Select from 'react-select'
import { Link, useHistory } from "react-router-dom"
import { register, getPerfis, get_view_user, edit_user, getRelations, postRelationUser, getFarmsUser, delFarmUser } from '../../../Services/user'
import makeAnimated from 'react-select/animated'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MyModal from '../../../Modal/MyModal'
import {
    fade,
    ThemeProvider,
    withStyles,
    makeStyles,
    createMuiTheme,
  } from '@material-ui/core/styles';
import { ButtonContainner } from '../../../Button/style'
import SelectMaterial from '../../../Forms/SelectMaterial'
import { ListFarmUser } from './ListFarmUser'

  export default function User(props) {
      
      
    let history = useHistory();
    const selectRelationRef = useRef(null)
    const [ modal, setModal ] = useState(false);
    const [ idFarm, setIdFarm ] = useState('');
    const [ loading, setLoading ] = useState(false)
    const [ pesquisa, setPesquisa ] = useState('')
    const [ display, setDisplay ] = useState('block')
    const [ farmsUsers, setFarmsUsers ] = useState([])
    const [ page, setPage ] = useState(1)
    const [ qtdPerPage, setQtdPerPage ] = useState(10)
    const [ order, setOrder ] = useState('asc')
    const [ th, setTh ] = useState('farm.name')
    const [ email, setEmail ] = useState('')
    const [ name, setName ] = useState('')
    const [ typeRelationUser, setTypeRelationUser ] = useState({options:[{title: 'Fazenda', value:1},{title: 'Parceiro', value:2},{title: 'Produtor', value:3}], getOptionLabel: options => options.title})
    const [ idRelationUser, setIdRelationUser ] = useState({})
    const [ dataRelation, setDataRelation ] = useState({
        options:[],
        getOptionLabel: (option) => ''

    })
    const [ objectsRelation, setObjectsRelation ] = useState([])
    const [ image, setImage ] = useState('')
    const [ password, setPassword ] = useState('')
    const [ confirm_password, setConfirmPassword ] = useState('')
    const [ roles, setRoles ] = useState({
        options:[],
        getOptionLabel: (option) => ''

    })
    const [ role, setRole ] = useState({})
    const [ error, setError ] = useState([])
    const [ success, setSuccess ] = useState('')
    const [ alert, setAlert ] = useState('')
    const [ successRelation, setSuccessRelation ] = useState('')
    const [ alertRelation, setAlertRelation ] = useState('')
    const [ errorConfirmPassword, setErrorConfirmPassword ] = useState('')
    const animatedComponents = makeAnimated();
    
    useEffect(() => {
        obterPerfis();
    }, [])
    
    useEffect(() => {
        viewPerId();
    }, [])

    useEffect(() => {
        getRelationsAll();
    }, [idRelationUser])

    useEffect(() => {
        getFarmsUsersAll();
    }, [props.match.params.id])
    
    function obterPerfis() {
        getPerfis()
        .then(resp => {
            setRoles({
                options: resp.data,
                getOptionLabel: options => options.description,
                id:(option) => option.id
            })
        })
    }

    function getFarmsUsersAll(){
        let idUser = props.match.params.id
        props.match.params.action &&
        getFarmsUser(page,qtdPerPage,order,th,pesquisa,idUser)
        .then(resp => setFarmsUsers(resp.data))
    }
        
       
    const useStyles = makeStyles((theme) => ({
        root: {
            display: 'flex',
            flexWrap: 'wrap',
            
            
        },
        margin: {
            '& label.Mui-focused': {
                color: '#BEBEBE',
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: '#FFFF',
                color: '#FFFF'
            },
            '& .MuiInput-underline:before': {
                borderBottom: '1px solid rgba(255, 255, 255, 0.42)'
            },
            '& .MuiInputBase-input':{
                color: '#FFFF'
            },
            '& .MuiFormLabel-root':{
                
                color: '#BEBEBE',
                fontSize: '12px',
                marginBottom: '20px'
            },
            margin: theme.spacing(1),
            width: '100%'
        },
    }));
        
    function getRelationsAll(){
        if(idRelationUser){

            switch (idRelationUser.value) {
                case 1:
                    return getRelations()
                    .then(resp => setDataRelation({
                        options: resp.data.farms,
                        getOptionLabel: options => options.name
                    }))
                case 2:
                    return getRelations()
                    .then(resp => setDataRelation({
                        options: resp.data.partners,
                        getOptionLabel: options => options.name
                    }))
                case 3:
                    return getRelations()
                    .then(resp => setDataRelation({
                        options: resp.data.producers,
                        getOptionLabel: options => options.name
                    }))
            
                default:
                    return []
            }
        }
    }
        
    function viewPerId() {
        props.match.params.action && (
            get_view_user(props.match.params.id)
            .then(resp => {
                setName(resp.data[0].name)
                setEmail(resp.data[0].email)
                setRole({id: resp.data[0].id_role, description: resp.data[0].role})
            })
        )
    }

    const onChangeModal = (idFarm) => {
        setModal(true);
        setIdFarm(idFarm);
        
    }

    const handleCloseExcluir = event => {
        setModal(false);
        setIdFarm('');
        
    }

    const handleExcluir = (e) => {
        e.preventDefault();
        setLoading(true);
        setModal(false)
        
        delFarmUser(props.match.params.id,idFarm)
        .then(resp => {
            setSuccess(resp.data.success)
            setError('')
            setLoading(false)
        
        })
        .then(resp => (
            setTimeout(()=> {
                setSuccess('')
            },4000)

        ))
        .catch(e => {
            setLoading(false)
            setError(e.response.data.error)
            setSuccess('')
            setModal(false)
            setTimeout(()=> {
                setError('')
            },4000)
        
        })
        .then(resp => (getFarmsUsersAll()))
        // .then(resp => {setState({success:resp.data.success, error:''})})

    }
            
    const handleForm=(e)=>{
        e.preventDefault();
        setLoading(true)
        console.log(role)
        const data  =  {
            name: name,
            email: email,
            password: password,
            idRole: parseInt(role.id),
            confirm_password: confirm_password,
        }
        
        if(password !== confirm_password)
        {
            return[
                setLoading(false),
                setErrorConfirmPassword('Erro ao cadastrar o usuário. As senhas não conferem!')
            ]
            
        }
        
        if(props.match.params.action === 'edit'){
            const id = props.match.params.id
            edit_user(id,data)
            .then(resp=> {
                setLoading(false)
                setAlert('')
                setErrorConfirmPassword('')
                setError([])
                setSuccess(resp.data.success)    
            })
            .then(resp => setTimeout(() => {
                window.location.reload()
            },2500))
            .catch(e => {
                setLoading(false)
                setErrorConfirmPassword('')
                setSuccess('')
                setError(e.response.data.error)
                setAlert(e.response.data.message ? "Os dados fornecidos são inválidos" : '')  
            })           
            
        }else{
            register(data)
            .then(resp=> {
                setLoading(false)
                setAlert('')
                setErrorConfirmPassword('')
                setError([])
                setSuccess(resp.data.success)    
            })
            .then(resp => setTimeout(() => {
                window.location.reload()
            },2500))
            .catch(e => {
                setLoading(false)
                setErrorConfirmPassword('')
                setSuccess('')
                setError(e.response.data.error)
                setAlert(e.response.data.message ? "Os dados fornecidos são inválidos" : '')  
            })              
        }
    }

    const handleRelations = e => {
        e.preventDefault();
        console.log(objectsRelation)
        setLoading(true)
        
        let newRelations = [];
        objectsRelation.map(desc => newRelations.push(desc.id))
        let data = {}
        if(idRelationUser.value === 1){
            data = {
                idUser: parseInt(props.match.params.id) || '',
                farms: newRelations
            }
        }
        else if(idRelationUser.value === 2){
            data = {
                idUser: parseInt(props.match.params.id) || '',
                partners: newRelations
            }
        }
        else if(idRelationUser.value === 3){
            data = {
                idUser: parseInt(props.match.params.id) || '',
                producers: newRelations
            }
        }
        // console.log(data)

        postRelationUser(data)
        .then(resp=> {
            setLoading(false)
            setAlertRelation('')
            setSuccessRelation(resp.data.success) 
            getFarmsUsersAll()   
        })
        .then(resp => setTimeout(() => {
            setIdRelationUser({})
            setObjectsRelation([])
            setSuccessRelation('')  
        },2500))
        .catch(e => {
            setLoading(false)
            setSuccessRelation('')
            setAlertRelation(e.response.data.error)  
        })       

    }
    
    const classes = useStyles();
    return (
        <div className="wrapper">
            <Sidebar />
            <Header />
            <StylePage>
                {loading === true ? (
                    <Content>
                        <ReactLoading type="spinningBubbles" color="blue" height={200} width={100} />
                    </Content>
                    
                ):('')}
                {alert ? (
                    <Alert type="danger">
                        {alert}
                    </Alert>
                ):('')}
                {success ? (
                    <Alert type="success">
                        {success}
                    </Alert>
                ):('')}
                <MyModal
                    show={modal}
                    onHide={handleCloseExcluir}
                    title="Excluir Fazenda"
                    content="Tem certeza que deseja excluir a fazenda do usuário ?"
                    cancel={handleCloseExcluir}
                    del={handleExcluir}     
                    to="#/"
                    type="danger"
                    textButton="Excluir"                   
                />
                <div style={{display: loading === true ? ('none'):('block')}}>
                    <section className="content-header">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <h2 style={{color: '#00ABFF', textAlign: 'center'}}>{props.match.params.action === 'edit' ? "Edição de Usuário" : props.match.params.action === 'view' ? "Visualização de Usuário" : "Cadastro de Usuário"}</h2>
                                </div>
                            </div>
                        </div>{/* /.container-fluid */}
                    </section>
                    <Form  className={classes.root} onSubmit={handleForm}>
                        <div className="row">
                            <div className='col-md-6'>
                                <TextField 
                                    className={classes.margin} 
                                    id="name" 
                                    name="name"
                                    label="Nome" 
                                    required={true}
                                    onChange={(e) => setName(e.target.value)}
                                    value={name}
                                    type="text"
                                    disabled={props.match.params.action === 'view' ? true : false}
                                />
                                {error ? (<p style={{color:'red'}}>{error.name}</p>):('')}
                            </div>
                            <div className='col-md-6'>
                                <TextField 
                                    className={classes.margin} 
                                    id="email" 
                                    name="email"
                                    label="Email" 
                                    required={true}
                                    onChange={(e) => setEmail(e.target.value)}
                                    value={email}
                                    type="email"
                                    disabled={props.match.params.action === 'view' ? true : false}
                                />
                                {error   ? (<p style={{color:'red'}}>{error.email}</p>):('')}
                            </div>
                        </div>
                        {!props.match.params.action && (
                            <div className="row"> 
                                <div className='col-md-6'>
                                    <TextField 
                                        className={classes.margin} 
                                        id="password" 
                                        name="password"
                                        label="Senha" 
                                        required={true}
                                        type="password"
                                        onChange={(e) => setPassword(e.target.value)}
                                        value={password}
                                        disabled={props.match.params.action === 'view' ? true : false}
                                    />
                                    {errorConfirmPassword ? (<p style={{color:'red'}}>{errorConfirmPassword}</p>):('')}
                                </div>
                                <div className='col-md-6'>
                                    <TextField 
                                        className={classes.margin} 
                                        id="confirmPassword" 
                                        name="confirmPassword" 
                                        label="Confirme a Senha" 
                                        required={true}
                                        type="password"
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        value={confirm_password}
                                        disabled={props.match.params.action === 'view' ? true : false}
                                    />
                                    {errorConfirmPassword ? (<p style={{color:'red'}}>{errorConfirmPassword}</p>):('')}
                                </div>
                            </div>
                        )}
                        <div className="row mt-4">
                            <div className={`col-md-12`}>
                                <Autocomplete
                                    {...roles}
                                    
                                    id="role" 
                                    name="role"
                                    value={role}
                                    onChange={(event, newValue) => {
                                        setRole(newValue);
                                    }}
                                    disabled={props.match.params.action === 'view' ? true : false}
                                    renderInput={(params) => <TextField {...params}  className={classes.margin} label="Perfil" required={true} />}
                                />
                            </div>
                        </div>
                        <br></br>
                        <div className="text-right">
                            <button style={{marginRight:'10px'}} type="button" className="btn btn-secondary" onClick={()=>history.goBack()}>Voltar</button>
                            {props.match.params.action === 'view' ? (
                                 <div style={{display: 'inline-block'}}>
                                    <ButtonContainner>
                                        <button onClick={(e) => {props.history.push(`/user/${props.match.params.id}/edit`);e.preventDefault()}} className="btn btn-primary">Editar</button>
                                    </ButtonContainner>
                                </div>
                            ) : (
                                <div style={{display: 'inline-block'}}>
                                    <ButtonContainner>
                                        <button disabled={props.match.params.action === 'view' ? true : false} type="submit" className="btn btn-primary">Salvar</button>                           
                                    </ButtonContainner>
                                </div>
                            )}
                        </div>       
                    </Form>
                    {props.match.params.action &&
                    <React.Fragment>

                    <div className="card" style={{background: 'linear-gradient(180deg,#1D3A59 0%,#0A1D3A 100%)', width: '100%'}}>
                        <a style={{background: 'linear-gradient(180deg,#1D3A59 0%,#0A1D3A 100%)', border: 0, color: '#FFFF'}} onClick={(e)=>setDisplay(`${display === 'none' ? 'block' : 'none'}`)} className="nav-link" className="btn btn-light">Relacionar Usuários <i className={`fas fa-${display === 'none' ?('angle-double-down'):('angle-double-up')}`}></i></a>

                    </div>

                        <div style={{display: display}}>

                            {/* <hr color="#4b545c"></hr> */}
                            {alertRelation ? (
                                <Alert type="danger">
                                    {alertRelation}
                                </Alert>
                            ):('')}
                            {successRelation ? (
                                <Alert type="success">
                                    {successRelation}
                                </Alert>
                            ):('')}
                            {props.match.params.action === 'edit' &&
                            
                                <Form className={classes.root} onSubmit={handleRelations} autoComplete="false">
                                    <section className="content-header">
                                        <div className="container-fluid">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <h2 style={{color: '#00ABFF', textAlign: 'center'}}>Relacionar Usuário</h2>
                                                </div>
                                            </div>
                                        </div>{/* /.container-fluid */}
                                    </section>
                                    <div className="row">
                                        <div className="col-md-5">
                                            <SelectMaterial 
                                                label="Relação"
                                                id="idRelationUser"
                                                name="idRelationUser"
                                                user={true}
                                                autoComplete="new-password"
                                                value={idRelationUser}
                                                onChange={(event, newValue) => {
                                                    setIdRelationUser(newValue);
                                                }}
                                                defaultProps={typeRelationUser}
                                                required={true}
                                                disabled={props.match.params.action === 'view' ? true : false}
                                            />
                                            {error ? (<p style={{color:'red'}}>{error.idQuestion}</p>):('')} 
                                        </div>
                                        <div className="col-md-5">
                                            <Autocomplete
                                                {...dataRelation}
                                                multiple
                                                id="dataRelation"
                                                name="dataRelation"
                                                value={objectsRelation}
                                                onChange={(event, newValue) => {
                                                    setObjectsRelation(newValue);
                                                }}
                                                ref={selectRelationRef}
                                                disabled={props.match.params.action === 'view' ? true : false}
                                                renderInput={(params)  => <TextField {...params} className={classes.margin} label={idRelationUser ? idRelationUser.value === 1 ? 'Fazendas *' : idRelationUser.value === 2 ? 'Parceiros *' : idRelationUser.value === 3 ? 'Produtores *' : 'Selecione *' : 'Selecione *'} />}
                                            />
                                            {objectsRelation ? objectsRelation.length <= 0 ? (

                                                <input
                                                    tabIndex={-1}
                                                    autoComplete="off"
                                                    style={{
                                                        opacity: 0,
                                                        width: "100%",
                                                        height: 0,
                                                        position: "absolute"
                                                    }}
                                                    defaultValue={objectsRelation}
                                                    onFocus={() => selectRelationRef.current.focus()}
                                                    required={true}
                                                />
                                                ):''
                                                : objectsRelation == null ? (
                                                <input
                                                tabIndex={-1}
                                                autoComplete="off"
                                                style={{
                                                    opacity: 0,
                                                    width: "100%",
                                                    height: 0,
                                                    position: "absolute"
                                                }}
                                                defaultValue={objectsRelation}
                                                onFocus={() => selectRelationRef.current.focus()}
                                                required={true}
                                                />
                                                ):''

                                                }
                                            
                                        </div>
                                        <div className="col-md-1">
                                            <div className="input-group mb-3 ml-5" style={{paddingTop:'23px'}}>
                                                <button type="submit" className="btn btn-primary"><span className="fas fa-plus"></span></button>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            }
                            <ListFarmUser farmUsers={farmsUsers} history={props.history} onDelete={onChangeModal} action={props.match.params.action} />
                        </div>
                    </React.Fragment>
                }
                </div>   
            </StylePage>
            <Footer />
        </div>
    )
}
