import { app } from './Api'

const getViewFarm = async (id) => {
    return app.get(`farm/${id}`)
}

const getFarms = async (page,qtd_per_page,order,th,pesquisa,idFarm) => {
    return app.get(`farm?page=${page}&qtd=${qtd_per_page}&order=${order}&th=${th}&pesquisa=${pesquisa}&idFarm=${idFarm || ''}`)
}

const postFarm = async (data) => {
    return app.post(`farm`, data)
}

const changeStatus =  async (id,data) => {
    return app.put(`farm/change_status/${id}`,data)
}

const putFarm = async (id,data) => {
    return app.put(`farm/${id}`,data)
}

const delFarm = async (id) => {
    return app.delete(`farm/${id}`)
}

const getResource = async () => {
    return app.get(`get_farm_resource`)
}


export { getViewFarm, getFarms, changeStatus, postFarm, putFarm, delFarm, getResource }