import React from 'react'
import { Link } from 'react-router-dom'
import Input from '../Forms/Input'
import Select from '../Forms/Select'

export default function Cabecalho(props) {

    const enterPressed = (event) => {
        var code = event.keyCode || event.which;
        if(code === 13) { //13 is the enter keycode
            props.action()
        } 
    }
    return (
        <div className="row mt-3">
        
            <div className="col-md-3">
                <label className="mt-2 ml-3" style={{color: '#FFFF', fontSize: '12px', margin: '5px'}}> Visualizar  
                <Select  style={{
                            background: 'rgb(0, 60, 80)',
                            /* FUNDO CAMPO DIGITAÇÃO/AZUL */

                            border: '2px solid #262D40',
                            /* CAMPOS DIGITÁVEIS AZUL ESC */
                            color: '#FFFF',
                            boxShadow: 'inset 0px -1px 1px rgba(255, 255, 255, 0.15), inset 1px 1px 5px rgba(0, 0, 0, 0.4), inset -3px -2px 3px rgba(255, 255, 255, 0.15)',
                            borderRadius: '100px'
                        }} value={parseInt(props.value)} onChange={props.onChange}>
                    <option value={9}>9</option>                           
                    <option value={24}>18</option>                          
                    <option value={49}>49</option>
                    <option value={99}>99</option>
                </Select> Registros
                </label>
            </div>
            <div className="col-md-5 mt-1">
                    <Input 
                        onKeyPress={enterPressed} 
                        value={props.valuePesquisa} 
                        type="text" 
                        onChange={props.changePesquisa}
                        style={{
                            background: 'rgb(0, 60, 80)',
                            position: 'absolute',
                            /* FUNDO CAMPO DIGITAÇÃO/AZUL */
                            color: '#FFFF',
                            border: '2px solid #262D40',
                            /* CAMPOS DIGITÁVEIS AZUL ESC */

                            boxShadow: 'inset 0px -1px 1px rgba(255, 255, 255, 0.15), inset 1px 1px 5px rgba(0, 0, 0, 0.4), inset -3px -2px 3px rgba(255, 255, 255, 0.15)',
                            borderRadius: '100px'
                        }} 
                    />
                    <span style={{color: '#FFFF', position: 'absolute', right:'4%', top: '32px' }} className="fas fa-search"></span> 
                
            </div>
            <div className="col-md-4" style={{marginTop: '28px'}}>
                <Link to={props.to} onClick={props.action} style={{marginLeft:'25px'}} className="btn btn-outline-info btn-sm">Pesquisar</Link>
                <Link to={props.to} onClick={props.clear} style={{marginLeft:'10px', marginRight:'10px'}} className="btn btn-outline-danger btn-sm">Limpar Busca</Link>
            </div>
  
            {/* <div className="row" style={{display: 'inline-block'}}>
                <div className="col-md-12">        
                    {props.buttonStatus} 
                </div>
            </div> */}
        </div> 
    )
}

// export default function Cabecalho(props) {

//     const enterPressed = (event) => {
//         var code = event.keyCode || event.which;
//         if(code === 13) { //13 is the enter keycode
//             props.action()
//         } 
//     }
//     return (
//         <React.Fragment>
//             {props.children}
//             <div className="col-sm-12 col-md-2">
//                 <div className="dataTables_length" id="example1_length">
//                     <label> Visualizar  
//                         <select style={{marginLeft: '5px'}} value={parseInt(props.value)} onChange={props.onChange} name="example1_length" aria-controls="example1" className="custom-select custom-select-sm form-control form-control-sm">
//                             <option value={10}>10</option>
//                             <option value={25}>25</option>
//                             <option value={50}>50</option>
//                             <option value={100}>100</option>
//                         </select> Registros
//                     </label>
//                 </div>
//             </div>
//             <div className="col-sm-12 col-md-10">
//                 <div id="example1_filter" className="dataTables_filter">
//                     <label>
                        
//                         {
//                             props.display === 'reimbursement' || props.display === 'visit' ? 
//                                 props.report === false && 
//                                     <React.Fragment>

//                                         <input 
//                                             onKeyPress={enterPressed} 
//                                             value={props.valuePesquisa} 
//                                             type="" 
//                                             onChange={props.changePesquisa} 
//                                             className="form-control form-control-sm" 
//                                             aria-controls="example1"
//                                         /> 
//                                         <span style={{marginLeft: '-9%'}} className="fas fa-search"></span> 
//                                     </React.Fragment>
//                             :
//                             <React.Fragment>

//                                 <input 
//                                     onKeyPress={enterPressed} 
//                                     value={props.valuePesquisa} 
//                                     type="" 
//                                     onChange={props.changePesquisa} 
//                                     className="form-control form-control-sm" 
//                                     aria-controls="example1"
//                                 /> 
//                                 <span style={{marginLeft: '-9%'}} className="fas fa-search"></span> 
//                             </React.Fragment>
                            
//                         }
                        
//                     </label>
                    
//                     <Link to={props.to} onClick={props.action} style={{marginLeft:'25px'}} className="btn btn-outline-info btn-sm">Pesquisar</Link>
//                     <Link to={props.to} onClick={props.clear} style={{marginLeft:'10px', marginRight:'10px'}} className="btn btn-outline-danger btn-sm">Limpar Busca</Link>
//                     {props.buttonCsv}
//                 </div>
//             </div>
//             <div className="row" style={{display: 'inline-block'}}>
//                 <div className="col-md-12">        
//                     {props.buttonStatus} 
//                 </div>
//             </div>
//         </React.Fragment> 
//     )
// }
