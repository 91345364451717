import React from 'react'

export default function Select(props) {
    return (
        <React.Fragment>
            <label>{props.label}</label>
            <select 
            
                className="form-control" 
                onChange={props.onChange} 
                name={props.name} 
                style={{width: '100%'}}
                value={props.value}
                required={props.required}  
                disabled={props.disabled}  
                defaultValue={props.defaultValue}
                style={props.style}
            >


                {props.children}
            </select>
        </React.Fragment>
    )
}
