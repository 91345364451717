import React from 'react'

export default function Form(props) {
    return (
        <form onSubmit={props.onSubmit || ''}  autoComplete="off">
            <div className="card-body">
                {props.children}
            </div>
        </form>
    )
}
