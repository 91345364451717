import React from 'react'
import { Link } from 'react-router-dom'
import "react-toggle/style.css"
import Toggle from 'react-toggle'
import Card from '../../../Card/Card'
import { ButtonAction } from '../../../Button/style'
import RecipeReviewCard from '../../../RecipeReviewCard/RecipeReviewCard'

export default function FlowPacksFarms(props) {
    const flowPackFarm = props.flowPacks || []
    return flowPackFarm.map((desc,i)=>(
        <div key={desc.id}  className="col-md-4 mt-4">
            <RecipeReviewCard
                title={desc.name}
                // actionRoute={props.action}
                rel={
                    desc.flows.map((res,i) => (
                        <p>
                            <span>
                                <a href="#/" onClick={(e) => {props.history.push(`/flow/${res.id}/view`);e.preventDefault()}}>{`Fluxo ${i+1}: ${res.name}`}</a>
                            </span>

                        </p>
                    ))
                }
                actions={
                    props.action &&
                    <div className="text-center">
                        <ButtonAction>
                            <button type="button" onClick={(e) => props.history.push(`/flow_pack/${desc.id}/view`)} className="btn"><i style={{color: '#FFFF', width:'30px'}} className='fas fa-eye'></i></button>
                            <button type="button" onClick={(e) => props.history.push(`/flow_pack/${desc.id}/edit`)} className="btn" ><i style={{color: '#FFFF', width:'30px'}} className='fas fa-edit'></i> </button>
                        </ButtonAction>

                    </div>
                
                }
            >
            </RecipeReviewCard>
        </div>
      
    ))
    
}
