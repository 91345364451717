const QuestionReducer = (state = {}, actions)=>{
    switch(actions.type){

        case 'Questions':
            return{...state, questions:actions.payload}

        case 'GetQuestions_Desc':
            return{...state, desc:actions.desc, asc:actions.asc, order:actions.order, th:actions.th}

        case 'GetQuestions_Asc':
            return{...state, desc:actions.desc, asc:actions.asc, order:actions.order, th:actions.th}

        case 'qtd_per_page_Questions':
            return{...state, qtd_per_page:actions.payload}

        case 'pesquisaQuestions':
            return{...state, pesquisa:actions.payload}

        case 'clearPesquisaQuestions':
            return{...state, pesquisa:actions.payload, idFlowFilter:actions.payload, idTopicFilter:actions.payload }

        case 'changePageQuestions':
            return{...state, page:actions.payload}
        
        case 'changeIdFlowFilterQuestion':
            return{...state, idFlowFilter:actions.payload}

        case 'changeIdTopicFilterQuestion':
            return{...state, idTopicFilter:actions.payload}

        default:
            return state;
    }
}
export default QuestionReducer;