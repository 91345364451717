import React from 'react'

export default function Input(props) {
    return (
        <React.Fragment>
            <label>{props.label}</label>
            <div className="input-group mb-3">
                {props.icon &&
                
                    <div className="input-group-prepend">
                        <span className="input-group-text ">
                            <i 
                                className={`fa ${props.icon}`} 
                            />
                        </span>
                    </div>
                }
                <input 
                    type={props.type} 
                    name={props.name} 
                    id={props.id}
                    // autocomplete={props.autocomplete}
                    className="form-control" 
                    placeholder={props.placeholder} 
                    onChange={props.onChange}
                    value={props.value || ""}
                    required={props.required || false}
                    disabled={props.disabled}
                    min={props.min || ""}
                    max={props.max || ""}
                    readOnly={props.readOnly}
                    maxLength={props.maxLength || ""}
                    onKeyPress={props.onKeyPress}
                    style={props.style}
                   
                />
            </div>
        </React.Fragment>
    )
}
