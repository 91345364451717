import { get_topic } from '../../../../Services/topic'

//Função que traz os dados do banco de dados
export const get = () =>{
    return[(dispatch,getState)=>{
        
        const order = getState().topic.order
        const th = getState().topic.th
        const qtd_per_page = getState().topic.qtd_per_page
        const pesquisa = getState().topic.pesquisa || ''       
        const page = getState().topic.page
        const idFlowFilter = getState().topic.idFlowFilter.id ? getState().topic.idFlowFilter.id : ''  

        get_topic(page,qtd_per_page,order,th,pesquisa,idFlowFilter)
        .then(resp=>dispatch({type:'Topics', payload:resp.data}))

    }]
}

//Função que ordena as colunas
export const order = (coluna) => {
    return [(dispatch,getState)=>{
        const asc = getState().topic.asc
      
        if(asc === true){
            dispatch({type:'GetTopics_Desc', desc:true, asc:false,order:'desc',th:coluna})
            
        }if(asc === false){
            dispatch({type:'GetTopics_Asc', asc:true, desc:false,order:'asc',th:coluna})
            
        }
     
    },get(1)]    
}

export const change_qtd_per_page = event => {
    return[{
        type:'qtd_per_page_Topics',
        payload:event.target.value
    },get(1)]
}

export const changePesquisa = event => {
    return[{
        type:'pesquisaTopics',
        payload:event.target.value
    }]
}

export const clearPesquisa = event => {
    return[{
        type:'clearPesquisaTopics',
        payload:'',
        idFlowFilter: {}
    },get(1)]
}

export const changePage = page => {
    return[{
        type: 'changePageTopics',
        payload: page
    },get()]
}

export const search = () => {
    return [
        (dispatch, getState) => {
            const pesquisa = getState().topic.pesquisa || ''
            if(pesquisa){
                return dispatch({type:'changePageTopics', payload:1})
            }
        }, get()
    ]
}