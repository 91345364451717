import React, { useState, useEffect } from 'react'
import { pagination } from '../../../Pagination/paginations'
import Pagination from '../../../Table/Pagination'
import Cabecalho from '../../../Table/Cabecalho'
import FlowPacksPartners from './FlowPacksPartners'

export function ListFlowPackPartner(props) {
    let flowPack = props.flowPacks.data || []
    return (
        <section className="content">
            <div className="card-body">
                
                <div className="row mt-3">
                    {
                        flowPack.length == 0 ? 
                            <div 
                                style={{
                                    color: '#FFFF', 
                                    textAlign: 'center', 
                                    width: '100%'
                                }}
                            >
                                <span>{`${!props.pesquisa ? 'Não existem Pacotes de Fluxos para este parceiro cadastrado no sistema. Relacione o parceiro à um Pacote de Fluxo' : 'Nenhum registro encontrado na pesquisa'}`}</span>
                            </div>
                        :''

                    }
                    <FlowPacksPartners flows={props.flows} history={props.history} changeStatus={props.changeStatusActive} flowPacks={props.flowPacks} onDelete={props.onDelete} />
                </div>
            
            </div>
        </section>
    )
}

