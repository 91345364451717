const FarmReducer = (state = {}, actions)=>{
    switch(actions.type){

        case 'Farms':
            return{...state, farms:actions.payload, lines:actions.lines}

        case 'GetFarms_Desc':
            return{...state, desc:actions.desc, asc:actions.asc, order:actions.order, th:actions.th}

        case 'GetFarms_Asc':
            return{...state, desc:actions.desc, asc:actions.asc, order:actions.order, th:actions.th}

        case 'qtd_per_page_Farms':
            return{...state, qtd_per_page:actions.payload}

        case 'pesquisaFarms':
            return{...state, pesquisa:actions.payload}

        case 'clearPesquisaFarms':
            return{...state, pesquisa:actions.payload}

        case 'changePageFarms':
            return{...state, page:actions.payload}

        default:
            return state;
    }
}
export default FarmReducer;