import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    background: 'linear-gradient(180deg, #1D3A59 0%, #0A1D3A 100%)',
    /* CAMPO/GRAD AZUL ESC */

    boxShadow: '1px 1px 4px rgba(0, 0, 0, 0.5)',
    borderRadius: '10px'
  },
  media: {
    height: 140,
  },
});

export default function MediaCard(props) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
        
      <CardActionArea>
        {/* <CardMedia
          className={classes.media}
          image="/static/images/cards/contemplative-reptile.jpg"
          title="Contemplative Reptile"
        /> */}
        <CardContent>
          <Typography gutterBottom variant="h5" style={{color: 'rgb(0, 171, 255)'}} component="h2">
            {props.title}
          </Typography>
          <Typography variant="body2" color="textSecondary" style={{color: '#FFFF'}} component="p">
            {props.children}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
          {props.actions}
        {/* <Button size="small" color="primary">
          Share
        </Button>
        <Button size="small" color="primary">
          Learn More
        </Button> */}
      </CardActions>
    </Card>
  );
}
