import React, { useState, useEffect } from 'react'
import { pagination } from '../../../Pagination/paginations'
import Pagination from '../../../Table/Pagination'
import Cabecalho from '../../../Table/Cabecalho'
import FarmsUsers from './FarmsUsers'

export function ListFarmUser(props) {
    let farmsUsers = props.farmUsers.data || []
    return (
        <section className="content">
            <div className="card-body">
                {farmsUsers.length > 0 || props.pesquisa ?
                    <Cabecalho to="#/" action={props.search} clear={props.clearPesquisa} valuePesquisa={props.pesquisa} value={props.qtd_per_page} onChange={props.change_qtd_per_page} changePesquisa={props.changePesquisa} />
                    :''
                }
                <div className="row mt-3">
                    {
                        farmsUsers.length == 0 ? 
                            <div 
                                style={{
                                    color: '#FFFF', 
                                    textAlign: 'center', 
                                    width: '100%'
                                }}
                            >
                                <span>{`${!props.pesquisa ? 'Não existem Fazendas para este usuário cadastrado no sistema. Relacione o usuário à uma fazenda nas instruções acima' : 'Nenhum registro encontrado na pesquisa'}`}</span>
                            </div>
                        :''

                    }
                    <FarmsUsers action={props.action} history={props.history} changeStatus={props.changeStatusActive} farmUsers={props.farmUsers} onDelete={props.onDelete} />
                </div>
                {farmsUsers.length > 0 &&
                    <div className="row">
                        <div className="col-12 mt-4">
                            <Pagination>{pagination(props.current_page,props.get,props.last_page,props.changePage)}</Pagination>
                        </div>
                    </div>
                }
            </div>
        </section>
    )
}

