import { getFlowPack } from '../../../../Services/flowPack'

//Função que traz os dados do banco de dados
export const get = () =>{
    return[(dispatch,getState)=>{
        
        const order = getState().flow_pack.order
        const th = getState().flow_pack.th
        const qtd_per_page = getState().flow_pack.qtd_per_page
        const pesquisa = getState().flow_pack.pesquisa || ''       
        const page = getState().flow_pack.page 

        getFlowPack(page,qtd_per_page,order,th,pesquisa)
        .then(resp=>dispatch({type:'FlowPacks', payload: resp.data}))

        // getFlowPack(page,qtd_per_page,order,th,pesquisa)
        // .then(resp=>dispatch({type:'FlowPacks', payload:{
        //     current_page: resp.data.current_page,
        //     data: resp.data.data.map(desc => ({
        //         id: desc.id,
        //         flow_pack: desc.flow_pack,
        //         flows: desc.flow
        //     })),
        //     first_page_url: resp.data.first_page_url,
        //     from: resp.data.from,
        //     last_page: resp.data.last_page,
        //     last_page_url: resp.data.last_page_url,
        //     per_page: resp.data.per_page,
        //     to: resp.data.to,
        //     total: resp.data.total
        // }}))

    }]
}

//Função que ordena as colunas
export const order = (coluna) => {
    return [(dispatch,getState)=>{
        const asc = getState().flow_pack.asc
      
        if(asc === true){
            dispatch({type:'GetFlowPacks_Desc', desc:true, asc:false,order:'desc',th:coluna})
            
        }if(asc === false){
            dispatch({type:'GetFlowPacks_Asc', asc:true, desc:false,order:'asc',th:coluna})
            
        }
     
    },get(1)]    
}

export const change_qtd_per_page = event => {
    return[{
        type:'qtd_per_page_FlowPacks',
        payload:event.target.value
    },get(1)]
}

export const changePesquisa = event => {
    return[{
        type:'pesquisaFlowPacks',
        payload:event.target.value
    }]
}

export const clearPesquisa = event => {
    return[{
        type:'clearPesquisaFlowPacks',
        payload:''
    },get(1)]
}

export const changePage = page => {
    return[{
        type: 'changePageFlowPacks',
        payload: page
    },get()]
}

export const search = () => {
    return [
        (dispatch, getState) => {
            const pesquisa = getState().flow.pesquisa || ''
            if(pesquisa){
                return dispatch({type:'changePageFlowPacks', payload:1})
            }
        }, get()
    ]
}