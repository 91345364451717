const FlowReducer = (state = {}, actions)=>{
    switch(actions.type){

        case 'Flows':
            return{...state, flows:actions.payload}

        case 'GetFlows_Desc':
            return{...state, desc:actions.desc, asc:actions.asc, order:actions.order, th:actions.th}

        case 'GetFlows_Asc':
            return{...state, desc:actions.desc, asc:actions.asc, order:actions.order, th:actions.th}

        case 'qtd_per_page_Flows':
            return{...state, qtd_per_page:actions.payload}

        case 'pesquisaFlows':
            return{...state, pesquisa:actions.payload}

        case 'clearPesquisaFlows':
            return{...state, pesquisa:actions.payload}

        case 'changePageFlows':
            return{...state, page:actions.payload}

        default:
            return state;
    }
}
export default FlowReducer;