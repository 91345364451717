import React from 'react'

export default function Footer() {
    return (

      <React.Fragment>

<footer className="main-footer" style={{background: 'rgb(38, 45, 64)'}}>
                
                <div className="float-right d-none d-sm-block">
                    <b>Version</b> 2.0
                </div>
                <strong>Copyright © 2020-2020 Rehagro TI</strong> Todos os direitos reservados
                
            </footer>
  {/* Control Sidebar */}
  <aside className="control-sidebar control-sidebar-dark">
      {/* Control sidebar content goes here */}
      <div id="sidebar-overlay"></div>
  </aside>
  {/* /.control-sidebar */}
</React.Fragment>
        
    )
}
