import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    background: 'linear-gradient(180deg, #1D3A59 0%, #0A1D3A 100%)',
    /* CAMPO/GRAD AZUL ESC */

    boxShadow: '1px 1px 4px rgba(0, 0, 0, 0.5)',
    borderRadius: '10px'
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

export default function RecipeReviewCard(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card className={classes.root}>
        {props.actionDisabled &&
        <CardHeader
            action={
                props.actionDisabled
            // <IconButton aria-label="settings">
            //     <MoreVertIcon />
            // </IconButton>
            }
        />
      }
      <CardContent>
        <Typography gutterBottom variant="h5" style={{color: 'rgb(0, 171, 255)', height: '80px', fontSize: '16px', marginBottom: '20px'}} component="h2">
            {props.title}
          </Typography>
          <Typography variant="body2" color="textSecondary" style={{color: '#FFFF'}} component="p">
            {props.children}
          </Typography>
      </CardContent>
      
          <React.Fragment>

            <CardActions disableSpacing>
            {props.actionRoute !== 'view' && (props.actions)}
                {props.rel !== false &&
                
                    <IconButton
                    className={clsx(classes.expand, {
                        [classes.expandOpen]: expanded,
                    })}
                    style={{color: '#FFFF'}}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="Mais Informações"
                    >
                    <ExpandMoreIcon />
                    </IconButton>
                }
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    {props.rel}
                </CardContent>
            </Collapse>
          </React.Fragment>
      
    </Card>
  );
}
