import { getFarms } from '../../../../Services/farm'

//Função que traz os dados do banco de dados
export const get = () =>{
    return[(dispatch,getState)=>{
        
        const order = getState().farm.order
        const th = getState().farm.th
        const qtd_per_page = getState().farm.qtd_per_page
        const pesquisa = getState().farm.pesquisa || ''       
        const page = getState().farm.page 

        getFarms(page,qtd_per_page,order,th,pesquisa)
        .then(resp=>dispatch({type:'Farms', payload:resp.data}))

    }]
}

//Função que ordena as colunas
export const order = (coluna) => {
    return [(dispatch,getState)=>{
        const asc = getState().farm.asc
      
        if(asc === true){
            dispatch({type:'GetFarms_Desc', desc:true, asc:false,order:'desc',th:coluna})
            
        }if(asc === false){
            dispatch({type:'GetFarms_Asc', asc:true, desc:false,order:'asc',th:coluna})
            
        }
     
    },get(1)]    
}

export const change_qtd_per_page = event => {
    return[{
        type:'qtd_per_page_Farms',
        payload:event.target.value
    },get(1)]
}

export const changePesquisa = event => {
    return[{
        type:'pesquisaFarms',
        payload:event.target.value
    }]
}

export const clearPesquisa = event => {
    return[{
        type:'clearPesquisaFarms',
        payload:''
    },get(1)]
}

export const changePage = page => {
    return[{
        type: 'changePageFarms',
        payload: page
    },get()]
}

export const search = () => {
    return [
        (dispatch, getState) => {
            const pesquisa = getState().farm.pesquisa || ''
            if(pesquisa){
                return dispatch({type:'changePageFarms', payload:1})
            }
        }, get()
    ]
}