import { app } from './Api'

const get_view_client = async (id) => {
    return app.get(`client/${id}`)
}

const get_customers = async (page,qtd_per_page,order,th,pesquisa) => {
    return app.get(`client?page=${page}&qtd=${qtd_per_page}&order=${order}&th=${th}&pesquisa=${pesquisa}`)
}

const post_client = async (data) => {
    return app.post(`client`, data)
}

const changeStatus =  async (id,data) => {
    return app.put(`client/change_status/${id}`,data)
}

const put_client = async (id,data) => {
    return app.put(`client/${id}`,data)
}

const del_client = async (id) => {
    return app.delete(`client/${id}`)
}

const postFlowPackPartner = async (data) => {
    return app.post(`post_flow_pack_partner`, data)
}

const getFLowPackPartner = async () => {
    return app.get(`get_flow_pack_partner`)
}

const delFlowPackPartner = async (idPartner,idFlowPack) => {
    return app.delete(`del_flow_pack_partner?idPartner=${idPartner}&idFlowPack=${idFlowPack}`)
}

const getFlowsPacksPartners = async (page,qtd_per_page,order,th,pesquisa,idPartner) => {
    return app.get(`get_flows_packs_partners?idPartner=${idPartner}&page=${page}&qtd=${qtd_per_page}&order=${order}&th=${th}&pesquisa=${pesquisa}`)
}


export { getFlowsPacksPartners, delFlowPackPartner, getFLowPackPartner, get_view_client, get_customers, changeStatus, post_client, put_client, del_client, postFlowPackPartner }