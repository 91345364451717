import { app } from './Api'

const get_view_question = async (id) => {
    return app.get(`question/${id}`)
}

const get_resource = async () => {
    return app.get(`get_resource_question`)
}

const get_question = async (page,qtd_per_page,order,th,pesquisa,idFlowFilter,idTopicFilter) => {
    return app.get(`question?page=${page}&qtd=${qtd_per_page}&order=${order}&th=${th}&pesquisa=${pesquisa}&idFlowFilter=${idFlowFilter}&idTopicFilter=${idTopicFilter}`)
}

const post_question = async (data) => {
    return app.post(`question`, data)
}

const changeStatus =  async (id,data) => {
    return app.put(`question/change_status/${id}`,data)
}

const put_question = async (id,data) => {
    return app.put(`question/${id}`,data)
}

const del_question = async (id) => {
    return app.delete(`question/${id}`)
}


export { get_view_question, get_question, changeStatus, post_question, put_question, del_question, get_resource }