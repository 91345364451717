import styled from "styled-components";

export const Content = styled.div`
    padding: 20px;
    /* width: 70%; */
    font-size: 15px;
    

    .subtitulo{
        font-size: 20px;
        margin-top: 30px;
    }

    .titulo{
        font-size: 30px;
    }

    .conteudo_politica, .cookies{
        margin: 30px;
    }

    
    
`;






