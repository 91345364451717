const TopicReducer = (state = {}, actions)=>{
    switch(actions.type){

        case 'Topics':
            return{...state, topics:actions.payload}

        case 'GetTopics_Desc':
            return{...state, desc:actions.desc, asc:actions.asc, order:actions.order, th:actions.th}

        case 'GetTopics_Asc':
            return{...state, desc:actions.desc, asc:actions.asc, order:actions.order, th:actions.th}

        case 'qtd_per_page_Topics':
            return{...state, qtd_per_page:actions.payload}

        case 'pesquisaTopics':
            return{...state, pesquisa:actions.payload}

        case 'clearPesquisaTopics':
            return{...state, pesquisa:actions.payload, idFlowFilter:actions.idFlowFilter}

        case 'changePageTopics':
            return{...state, page:actions.payload}

        case 'changeIdFlowFilter':
            return{...state, idFlowFilter:actions.payload}

        default:
            return state;
    }
}
export default TopicReducer;