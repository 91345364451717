import React from 'react'

export default function TextArea(props) {
    return (
        <div className="form-group">
            <label style={props.styleLabel}>{props.label}</label>
            <textarea 
                disabled={props.disabled} 
                className="form-control fas" 
                value={props.value} 
                rows={props.rows} 
                onChange={props.onChange} 
                placeholder={props.placeholder}
                maxLength={props.maxLength}
                style={props.style}
            >
                {props.children}
            </textarea>
        </div>
    )
}
