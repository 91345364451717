import React from 'react'
import NavItem from './NavItem'
import NavItemDropdown from './NavItemDropdown'
import { useSelector } from 'react-redux'

export default function Menu(props) {
    const perfis = useSelector( state => state.auth.user.perfis )
    // const id_user = useSelector( state => state.auth.user.id )
  
    return (
        <React.Fragment>
            <NavItem to="/index" item="Home" icon="fa fa-home" />
            <div className="mt-3" style={{borderBottom: "1px solid #dee2e6"}}></div>
            <a className="nav-link"><p style={{fontSize: '10px', color: "#708090"}}>Controle  Administrativo</p></a>
            <NavItem to="/users" item="Usuários" icon="fas fa-users" />
            <NavItem to="/customers" item="Parceiros" icon="fas fa-hands-helping" />
            <NavItem to="/producers" item="Produtores" icon="fas fa-people-carry" />
            <NavItem to="/farms" item="Fazendas" icon="fas fa-tractor" />
            <div className="mt-3" style={{borderBottom: "1px solid #dee2e6"}}></div>
            <a className="nav-link"><p style={{fontSize: '10px', color: "#708090"}}>Fluxos e Tópicos</p></a>
            <NavItem item="Pacote de Fluxos" to="/flow_packs" icon="fas fa-cubes" />
            <NavItem item="Fluxos" to="/flows" icon="fas fa-sitemap" />
            <NavItem item="Tópicos" to="/topics" icon="fas fa-list-ul" />
            {/* <NavItem item="Linhas" to="/lines" icon="fas fa-bezier-curve" /> */}
            <div className="mt-3" style={{borderBottom: "1px solid #dee2e6"}}></div>
            <a className="nav-link"><p style={{fontSize: '10px', color: "#708090"}}>Questões</p></a>
            <NavItem to="/questions" item="Perguntas" icon="fas fa-question" />
            {/* <NavItem to="/actions" item="Ações" icon="fas fa-location-arrow" /> */}
           
            {/* <NavItem to="/projects" item="Projetos" icon="fas fa-file-signature" />
            <NavItem item="Reembolsos" to="/refunds" icon="fas fa-hand-holding-usd" />
            <NavItem item="Reembolso Compartilhado" to="/refunds_shared" icon="fas fa-funnel-dollar" />        */}
        </React.Fragment>

    )
    
}

