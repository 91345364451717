import { app } from './Api'

const getViewFlowPack = async (id) => {
    return app.get(`flow_pack/${id}`)
}

const getFlowPack = async (page,qtd_per_page,order,th,pesquisa) => {
    return app.get(`flow_pack?page=${page}&qtd=${qtd_per_page}&order=${order}&th=${th}&pesquisa=${pesquisa}`)
}

const getFlows = async () => {
    return app.get(`flow_pack_resource`)
}

const postFlowPack = async (data) => {
    return app.post(`flow_pack`, data)
}

const changeStatus =  async (id,data) => {
    return app.put(`change_status_flow_pack/${id}`,data)
}

const putFlowPack = async (id,data) => {
    return app.put(`flow_pack/${id}`,data)
}

const delFlowPack = async (id) => {
    return app.delete(`flow_pack/${id}`)
}


export { getViewFlowPack, getFlowPack, changeStatus, postFlowPack, putFlowPack, delFlowPack, getFlows }