import React, { useState, useEffect } from 'react'
import Header from '../../../Header/Header'
import Sidebar from '../../../Sidebar/Sidebar'
import Footer from '../../../Footer/Footer'
import StylePage from '../../../Style Page/StylePage'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import { search, changePage, get, order, change_qtd_per_page, changePesquisa, clearPesquisa } from './Actions/actions'
import { pagination } from '../../../Pagination/paginations'
import Pagination from '../../../Table/Pagination'
import Cabecalho from '../../../Table/Cabecalho'
import Alert from '../../../Alerts/Alert'
import MyModal from '../../../Modal/MyModal'
import ReactLoading from 'react-loading'
import { Content } from '../../../LoadingStyle/StyleLoading'
import { changeStatus, del_topic, get_resource } from '../../../Services/topic'
import Topics from './Topics'
import { ButtonContainner } from '../../../Button/style'
import SelectMaterial from '../../../Forms/SelectMaterial'
import { useSelector, useDispatch } from 'react-redux'


function GridTopic(props) {
    const [ loading, setLoading ] = useState(false);
    const [ modal, setModal ] = useState(false);
    const [ id, setId ] = useState('');
    const [ success, setSuccess ] = useState('');
    const [ error, setError ] = useState('');
    const [ display, setDisplay ] = useState('none')
    const [ flows, setFlows ] = useState({
        options:[],
        getOptionLabel: (option) => ''

    })
    const cpf_cnpj = 'cpf_cnpj'
    const email = 'email'
    const nome = 'nome'
    const idFlowFilter = useSelector(state => state.topic.idFlowFilter)
    const dispatch = useDispatch()
   
    const getTopics = () => {
        props.get();
    }

    useEffect(() => {
        getTopics();
        return () => {
            getTopics()
        }
        
    },[])

    useEffect(() => {
        getResource()
    },[])

    function getResource(){
        get_resource()
        .then(resp => {
            setFlows({
                options: resp.data,
                getOptionLabel: options => options.name
            })
        })
    }

    
    const changeStatusActive = (event) =>{
        const id = event.target.id
        let checked = event.target.checked === true ? 1 : 0
        const data = {
            ativo:checked,     
        }
        changeStatus(id, data)  
        .then(resp => getTopics())
        .catch(resp => getTopics())
    }
     
    const onChangeModal = (id_prod) => {
        setModal(true);
        setId(id_prod);
        
    }

    const handleCloseExcluir = event => {
        setModal(false);
        setId('');
        
    }

    const handleExcluir = (e) => {
        e.preventDefault();
        setLoading(true);
        setModal(false)
        
        del_topic(id)
        .then(resp => {
            setSuccess(resp.data.success)
            setError('')
            setLoading(false)
        
        })
        .then(resp => (
            setTimeout(()=> {
                setSuccess('')
            },4000)

        ))
        .catch(e => {
            setLoading(false)
            setError(e.response.data.error)
            setSuccess('')
            setModal(false)
            setTimeout(()=> {
                setError('')
            },4000)
        
        })
        .then(resp => (props.get()))
        // .then(resp => {setState({success:resp.data.success, error:''})})

    }

    return (
        <div className="wrapper">
            <Header />
            <Sidebar />
            <StylePage>

                {loading === true ? (
                    <Content>
                        <ReactLoading type="spinningBubbles" color="blue" height={200} width={100} />
                    </Content>
                    
                ):('')}
                {success ? (
                    <Alert type="success">{success}</Alert>
                ):("")}
                {error ? (
                    <Alert type="danger">{error}</Alert>
                ):("")}
                <MyModal
                    show={modal}
                    onHide={handleCloseExcluir}
                    title="Excluir Tópico"
                    content="Tem certeza que deseja excluir o tópico ?"
                    cancel={handleCloseExcluir}
                    del={handleExcluir}     
                    to="#/"
                    type="danger"
                    textButton="Excluir"                   
                />
                <div style={{display: loading === true ? ('none'):('block')}}>

                    <section className="content">
                        <section className="content-header">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-sm-6">
                                        <h2 style={{color: '#00ABFF'}}>Registros de Tópicos</h2>
                                    </div>
                                    <div className="text-right col-sm-6">
                                            <ButtonContainner>
                                                <button type="button" onClick={(e) => {props.history.push(`/topic`);e.preventDefault()}} className="btn btn-primary">Cadastro</button>
                                            </ButtonContainner>
                                        {/* <Link to="/user" className="btn btn-primary">Cadastro</Link> */}
                                    </div>
                                </div>
                            </div>{/* /.container-fluid */}
                        </section>
                        <div className="card-body">
                            {props.topics.length > 0 || props.pesquisa ?
                                <Cabecalho to="#/" action={props.search} clear={props.clearPesquisa} valuePesquisa={props.pesquisa} value={props.qtd_per_page} onChange={props.change_qtd_per_page} changePesquisa={props.changePesquisa} />
                                :''
                            }
                            <div className="text-right">
                                <a style={{background: '#262D40', border: 0, color: '#FFFF'}} onClick={(e)=>setDisplay(`${display === 'none' ? 'block' : 'none'}`)} className="nav-link" className="btn btn-light"><i className={`fas fa-${display === 'none' ?('angle-double-down'):('angle-double-up')}`}></i></a>
                            </div>
                            <div className="row" style={{display: `${display}`}}>
                                <div className="col-md-12">
                                    <SelectMaterial 
                                        user={true}
                                        label="Fluxo"
                                        id="idFlowFilter"
                                        name="idFlowFilter"
                                        value={idFlowFilter}
                                        onChange={(event, newValue) => {
                                            dispatch({type:'changeIdFlowFilter', payload:newValue})
                                        }}
                                        defaultProps={flows}
                                    />
                                </div>
                            </div>
                            <div className="row mt-3">
                                {
                                    props.topics.length == 0 ? 
                                        <div 
                                            style={{
                                                color: '#FFFF', 
                                                textAlign: 'center', 
                                                width: '100%'
                                            }}
                                        >
                                            <span>{`${!props.pesquisa ? 'Não existem Tópicos cadastrados no sistema. Clique no botão Cadastro para inserir um Tópico no Sistema' : 'Nenhum registro encontrado na pesquisa'}`}</span>
                                        </div>
                                    :''

                                }
                                <Topics history={props.history} changeStatus={changeStatusActive} topics={props.topics} onDelete={onChangeModal} />
                            </div>
                            {props.topics.length > 0 &&
                                <div className="row">
                                    <div className="col-12 mt-4">
                                        <Pagination>{props.pagination(props.current_page,props.get,props.last_page,props.changePage)}</Pagination>
                                    </div>
                                </div>
                            }
                        </div>
                    </section>



                    {/* <Table 
                        view={
                            <div className="col-sm-12 col-md-5">
                                <div className="dataTables_info" id="example1_info" role="status" aria-live="polite">
                                    Exibindo de {props.from} à {props.to} do total de {props.qtd_total}
                                </div>
                            </div>
                        }
                            
                        cabecalho={
                            
                            <Cabecalho to="#/" action={props.search} clear={props.clearPesquisa} valuePesquisa={props.pesquisa} value={props.qtd_per_page} onChange={props.change_qtd_per_page} changePesquisa={props.changePesquisa} />
   
                        }
                        pagination={<Pagination>{props.pagination(props.current_page,props.get,props.last_page,props.changePage)}</Pagination>} title="Registros de Tópicos"
                        button={
                            <Link to="/topic" className="btn btn-primary">Cadastro</Link>
                            
                            
                        }>
                        <Thead>
                            <th>FLUXO</th> 
                            <th>NOME<Link to="#/" style={{float:'right'}} onClick={()=>props.order(nome)}><i className={`right fas fa-sort-amount-${props.th === 'nome' ? (props.asc === true ?('down'):('up')):('down')}-alt`}></i></Link></th>
                            <th>AÇÕES</th>
                        </Thead>
                        <Tbody>
                            <Topics changeStatus={changeStatusActive} topics={props.topics} onDelete={onChangeModal} />
                        </Tbody>
                        <Tfoot> 
                            <th>FLUXO</th>
                            <th>NOME</th>
                            <th>AÇÕES</th>
                        </Tfoot>
                    </Table>         */}
                </div>
            </StylePage>
            
            
            <Footer />
        </div>
    )
}

const mapStateToProps = state => ({
    topics:state.topic.topics.data || [],
    current_page:state.topic.topics.current_page,
    last_page:state.topic.topics.last_page,
    from:state.topic.topics.from,
    to:state.topic.topics.to,
    qtd_total:state.topic.topics.total,
    qtd_per_page:state.topic.qtd_per_page,
    pesquisa:state.topic.pesquisa,
    perfis:state.auth.user.perfis,
    id_role:state.auth.user.id_role,
    asc:state.topic.asc,
    th:state.topic.th  
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({
        pagination, 
        get, 
        order, 
        change_qtd_per_page, 
        changePesquisa, 
        clearPesquisa,
        changePage,
        search 
    }, dispatch);

export default connect(mapStateToProps,mapDispatchToProps)(GridTopic)
