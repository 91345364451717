import React from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {  Content } from './styles'
import Sidebar from '../../Sidebar/Sidebar'
import Header from '../../Header/Header'
import Footer from '../../Footer/Footer'
import { Link } from 'react-router-dom'


function Index(props) {
    // const explode = `${props.name}`.split(' ')
    // const name = explode[0]
    return (
        <React.Fragment>
            <Sidebar />
            <Header />               
            <div className="content-wrapper">
                <Content>
                    
                    <section className="content">
                        <div className="row" style={{marginTop: '10%'}}>

                            {/* <div className="row" style={{marginTop:'40px',marginLeft:'10px'}}>
                                <div className="col-md-12">
                                    <Link style={{marginBottom:'8px',fontSize:'15px', width:'250px', backgroundColor: '#004a34'}}  to="/visits" id="atividade" className="btn btn-success btn-lg" role="button">Lançamentos de Visitas</Link>

                                </div>
                                <div className="col-md-12">

                                    <Link style={{marginBottom:'8px',fontSize:'15px',width:'250px', backgroundColor: '#004a34'}} to="/refunds" className="btn btn-success btn-lg" role="button">Lançamentos de Reembolsos</Link>
                                </div>
                                <div className="col-md-12">

                                    <Link  to="/refunds_shared" style={{fontSize:'15px',width:'250px', backgroundColor: '#004a34'}} className="btn btn-success btn-lg" role="button">Lançamentos de Reembolsos Compartilhados</Link>
                                </div>

                            </div> */}
                            {/* /.col */}
                        </div>
                        {/* /.row */}  
                    </section>                                                 
                  
                </Content>
            </div> 
            <Footer />  
        </React.Fragment>       
    )

}
const mapStateToProps = state => ({
    name:state.auth.user.name
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({}, dispatch);

export default connect(mapStateToProps,mapDispatchToProps)(Index)
