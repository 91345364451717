import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Wrapper, LogoStyle, Content, ContentRemember, Container, ButtonContainner } from './styles'
import { authentication } from '../../Services/auth'
import Alert from '../../Alerts/Alert'
import Rehagro from '../../Imagens/rehagro_azul.png'


function Login(props) {
    const [ email, setEmail ] = useState('')
    const [ password, setPassword ] = useState('')
    const [ error, setError ] = useState('')
    const [ success, setSuccess ] = useState('')


    const handleForm = (e) => {
        e.preventDefault();
        const data = { email: email, password: password }  
        authentication(data) 
        .then(res =>{
            localStorage.setItem("token", res.data.access_token);
            props.setLogin(res.data.user)  
        })
        .then(res => props.history.push('/index'))
    
        .catch(e => setError(e.response.data.error))
    }

    const handleEmail = (e) =>{
        e.preventDefault();
        const value = e.target.value;
        setEmail(value)   
    }

    const handlePassword = (e) =>{
        e.preventDefault();
        const value = e.target.value;
        setPassword(value)   
    }

    return (
        <React.Fragment>
            <Container>
                
                    <Wrapper/>
                      
                   
               

                    {/* <div className="login-box">             */}
                        {/* /.login-logo */}
                        {/* <div className="card"> */}
                            <Content>
                                <LogoStyle>
                                    <img src={Rehagro} width="130px" alt="Logo Rehagro" className="d-block"  />
                                </LogoStyle>
                            <div className="card-body login-card-body">
                            {error.errors ?( <p className="errors" style={{color: '#DC143C'}}>{error.errors}</p>):(success)}
                                <i style={{marginLeft:'125px'}} className="icon-reading icon-2x text-slate-300 border-slate-300 border-3 rounded-round p-3 mb-3 mt-1"></i>
                                {/* <h5 className="login-box-msg">Entre com suas credenciais</h5> */}
                                {/* <p className="login-box-msg">Entre com suas credenciais</p> */}
                                {
                                    error ? (
                                        <div>
                                            <Alert type="danger" hidden="true">
                                                {error}
                                            </Alert>
                                        </div>
                                    ) :('')                                         
                                }
                                <form onSubmit={handleForm}>
                                    <div className="input-group mb-3">
                                        <input style={{borderRadius: '25px', marginTop: '30px'}} onChange={handleEmail} type="email" name="email" className="form-control" placeholder="Digite seu E-mail" />
                                        {/* <div className="input-group-append"> */}
                                            {/* <div className="input-group-text"> */}
                                                <span style={{position: 'absolute', marginLeft: '90%', marginTop: '15%'}} className="fa fa-envelope" />
                                            {/* </div> */}
                                        {/* </div> */}
                                    </div>
                                    <div className="input-group mb-3">
                                        <input style={{borderRadius: '25px'}} onChange={handlePassword} type="password" name="password" className="form-control" placeholder="Digite sua Senha" />
                                        {/* <div  className="input-group-append"> */}
                                            {/* <div className="input-group-text"> */}
                                                <span style={{position: 'absolute', marginLeft: '90%', marginTop: '5%'}} className="fa fa-lock" />
                                            {/* </div> */}
                                        {/* </div> */}
                                    </div>
                                    <div className="row">
                                        {/* <div className="col-8">
                                            <div className="icheck-primary">
                                                <input type="checkbox" id="remember" />
                                                <label htmlFor="remember">
                                                Lembrar-me
                                                </label>
                                            </div>
                                        </div> */}
                                    
                                            <div className="col-md-12">
                                                <ButtonContainner>
                                                    <button style={{borderRadius: '25px'}} type="submit" className="btn btn-success btn-block">Entrar</button>
                                                </ButtonContainner>                                       
                                            </div>
                                    </div>                                    
                                </form>
                                <div className="social-auth-links text-center mb-2 mt-4"> 
                                <ContentRemember>
                                    
                                    <p className="mb-1">
                                        <a href="/send_email_remember">Esqueceu sua Senha ?</a>                                                                                                   
                                    </p>                             
                                </ContentRemember> 
                                                                
                                </div>
                                
                            </div>
                        {/* /.login-card-body */}
                            </Content>
                        {/* </div> */}
                    {/* </div> */}

                {/* </Wrapper> */}
                    
                </Container>
                
            
            {/* /.login-box */}
        </React.Fragment>
    )
    
}
const mapDispatchToProps = dispatch => {
    return{
        setLogin:(user) => dispatch({type: "SET_LOGIN", payload: user})           
    }
}
export default connect(null,mapDispatchToProps)(Login);