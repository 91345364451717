import React from 'react';
import { Content } from './style';

// import { Container } from './styles';

function TermsAndConditions() {
  return (
        <Content>
            <section className="conteudo_politica">
                    <h2 className="subtitulo">
                        1. Termos
                    </h2>
                    <p>
                        Ao acessar ao aplicativo Rehagro, concorda em cumprir estes termos de serviço, todas as leis e regulamentos aplicáveis ​​e concorda que é responsável pelo cumprimento de todas as leis locais aplicáveis. Se você não concordar com algum desses termos, está proibido de usar ou acessar este aplicativo. Os materiais contidos neste aplicativo são protegidos pelas leis de direitos autorais e marcas comerciais aplicáveis.
                    </p>
            </section>
            <section className="conteudo_politica">
                <h2 className="subtitulo">
                    2. Uso de Licença
                </h2>
                <p>

                    É concedida permissão para baixar temporariamente uma cópia dos materiais (informações ou software) no aplicativo Rehagro , 
                    apenas para visualização transitória pessoal e não comercial. Esta é a concessão de uma licença, não uma transferência de título e, 
                    sob esta licença, você não pode: 
                </p>
                <ul>
                    <li>
                        modificar ou copiar os materiais; 

                    </li>
                    <li>

                        usar os materiais para qualquer finalidade comercial ou para exibição pública (comercial ou não comercial); 
                    </li>
                    <li>
                        tentar descompilar ou fazer engenharia reversa de qualquer software contido no aplicativo Rehagro; 

                    </li>
                    <li>
                        remover quaisquer direitos autorais ou outras notações de propriedade dos materiais; ou 

                    </li>
                    <li>
                        transferir os materiais para outra pessoa ou 'espelhe' os materiais em qualquer outro servidor.
                    </li>
                </ul>
                <p>
                        Esta licença será automaticamente rescindida se você violar alguma dessas restrições e poderá ser rescindida por Rehagro a qualquer momento. Ao encerrar a visualização desses materiais ou após o término desta licença, você deve apagar todos os materiais baixados em sua posse, seja em formato eletrónico ou impresso.
                </p>


            </section>
            <section className="conteudo_politica">
                <h2 className="subtitulo">

                    3. Isenção de responsabilidade
                </h2>
                <ul>
                    <li>
                        Os materiais no aplicativo da Rehagro são fornecidos 'como estão'. Rehagro não oferece garantias, expressas ou implícitas, e, 
                        por este meio, isenta e nega todas as outras garantias, incluindo, sem limitação, garantias implícitas ou condições de comercialização, 
                        adequação a um fim específico ou não violação de propriedade intelectual ou outra violação de direitos.
                    </li>
                    <li>
                        Além disso, o Rehagro não garante ou faz qualquer representação relativa à precisão, aos resultados prováveis ​​ou à confiabilidade 
                        do uso dos materiais em seu aplicativo ou de outra forma relacionado a esses materiais ou em aplicativos vinculados a este aplicativo.
                    </li>
                </ul>
            </section>
            <section className="conteudo_politica">
                <h2 className="subtitulo">
                    4. Limitações
                </h2>
                <p>
                    Em nenhum caso o Rehagro ou seus fornecedores serão responsáveis ​​por quaisquer danos 
                    (incluindo, sem limitação, danos por perda de dados ou lucro ou devido a interrupção dos negócios) 
                    decorrentes do uso ou da incapacidade de usar os materiais em Rehagro, mesmo que Rehagro ou um representante autorizado da 
                    Rehagro tenha sido notificado oralmente ou por escrito da possibilidade de tais danos. Como algumas jurisdições não permitem 
                    limitações em garantias implícitas, ou limitações de responsabilidade por danos consequentes ou incidentais, essas 
                    limitações podem não se aplicar a você.

                </p>
            </section>
            <section className="conteudo_politica">
                <h2 className="subtitulo">
                    5. Precisão dos materiais
                </h2>
                <p>
                    Os materiais exibidos no aplicativo da Rehagro podem incluir erros técnicos, tipográficos ou fotográficos. 
                    Rehagro não garante que qualquer material em seu aplicativo seja preciso, completo ou atual. 
                    Rehagro pode fazer alterações nos materiais contidos em seu aplicativo a qualquer momento, sem aviso prévio. No entanto, 
                    Rehagro não se compromete a atualizar os materiais.
                </p>
            </section>
            <section className="conteudo_politica">
                <h2 className="subtitulo">
                    6. Links
                </h2>
                <p>
                    O Rehagro não analisou todos os aplicativos vinculados ao seu aplicativo e não é responsável pelo conteúdo de nenhum aplicativo vinculado.
                    A inclusão de qualquer link não implica endosso por Rehagro do aplicativo. O uso de qualquer aplicativo vinculado é por conta e risco do usuário.
                </p>
                <h3>
                    Modificações
                </h3>
                <p>
                    O Rehagro pode revisar estes termos de serviço do aplicativo a qualquer momento, sem aviso prévio. Ao usar este aplicativo, 
                    você concorda em ficar vinculado à versão atual desses termos de serviço.
                </p>
                <h3>
                    Lei aplicável
                </h3>
                <p>
                    Estes termos e condições são regidos e interpretados de acordo com as leis do Rehagro e você se submete irrevogavelmente à 
                    jurisdição exclusiva dos tribunais naquele estado ou localidade.
                </p>
            </section>
        </Content>
    )
  
}

export default TermsAndConditions;