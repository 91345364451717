import React from 'react'

//Função de Paginação
export const pagination = (current_page,get,last_page,page)=>{
    let items = [];
    
    
        //Verifica e cria o botão anterior na paginação
    if(current_page === 1){
        items.push(
            <li key={Math.floor(Math.random() * 65536)} className="paginate_button page-item disabled"></li>
        )
    }else{
        items.push(
            <li key={Math.floor(Math.random() * 65536)} className="paginate_button page-item">
                <button style={{borderRadius: '20px'}} className="page-link" onClick={()=>page(current_page-1)}><i className="fas fa-angle-double-left"></i></button>
            </li>
        )
    }
        
    var n = 6
    
    if(last_page<=n){
        
        //Verifica e cria as paginações para exibir de 1 à 10 páginas
        for(let i=1;i<=last_page;i++){ 
            if(i === current_page){
            items.push(
                <li key={Math.floor(Math.random() * 65536)} className="paginate_button page-item active">
                    <button style={{borderRadius: '20px'}} className="page-link" onClick={()=>page(i)}>{i}</button>
                </li>
            )
            }else{
                items.push(
                    <li key={Math.floor(Math.random() * 65536)} className="paginate_button page-item">
                        <button style={{borderRadius: '20px'}} className="page-link" onClick={()=>page(i)}>{i}</button>
                    </li>
                )
            }
        }
    }else 
        //Verifica e cria as paginações para acima de 10 páginas;
        
        if(current_page - (n/2) <= 1)
            var inicio = 1;
        else if(last_page - current_page < n)
             inicio = last_page - n  + 1
        else
            inicio = current_page - (n/2)

        var fim = inicio + n - 1
    
    for(let i=inicio;i<=fim;i++){ 
            
        if(i === current_page){
            items.push(
                <li key={Math.floor(Math.random() * 65536)} className="paginate_button page-item active">
                    <button style={{borderRadius: '20px'}} className="page-link" onClick={()=>page(i)}>{i}</button>
                </li>
            )			
        }else{
            items.push(
                <li key={Math.floor(Math.random() * 65536)} className="paginate_button page-item">
                    <button style={{borderRadius: '20px'}} className="page-link" onClick={()=>page(i)}>{i}</button>
                </li>
            )
        }
        
    }
    
    //Verifica e cria o Botão próximo
    if(last_page === current_page){
        items.push(
            <li key={Math.floor(Math.random() * 65536)} className="paginate_button page-item disabled"></li>
        )
    }else{
        items.push(
            <li key={Math.floor(Math.random() * 65536)}>
                <button style={{borderRadius: '20px'}} className="page-link" onClick={()=>page(current_page + 1)}><i className="fas fa-angle-double-right"></i></button>
            </li>
        )
    }
    
    return items.map(desc => (
        <li key={desc.key} className={desc.props.className} style={{marginLeft: '8px', borderRadius: '20px'}}>{desc.props.children}</li>
    ))
  

        
}


