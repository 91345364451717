import { get_question } from '../../../../Services/question'

//Função que traz os dados do banco de dados
export const get = () =>{
    return[(dispatch,getState)=>{
        
        const order = getState().question.order
        const th = getState().question.th
        const qtd_per_page = getState().question.qtd_per_page
        const pesquisa = getState().question.pesquisa || ''       
        const page = getState().question.page 
        const idFlowFilter = getState().question.idFlowFilter.id ? getState().question.idFlowFilter.id : ''
        const idTopicFilter = getState().question.idTopicFilter.id ? getState().question.idTopicFilter.id : '' 

        get_question(page,qtd_per_page,order,th,pesquisa,idFlowFilter,idTopicFilter)
        .then(resp=>dispatch({type:'Questions', payload:resp.data}))

    }]
}

//Função que ordena as colunas
export const order = (coluna) => {
    return [(dispatch,getState)=>{
        const asc = getState().question.asc
      
        if(asc === true){
            dispatch({type:'GetQuestions_Desc', desc:true, asc:false,order:'desc',th:coluna})
            
        }if(asc === false){
            dispatch({type:'GetQuestions_Asc', asc:true, desc:false,order:'asc',th:coluna})
            
        }
     
    },get(1)]    
}

export const change_qtd_per_page = event => {
    return[{
        type:'qtd_per_page_Questions',
        payload:event.target.value
    },get(1)]
}

export const changePesquisa = event => {
    return[{
        type:'pesquisaQuestions',
        payload:event.target.value
    }]
}

export const clearPesquisa = event => {
    return[{
        type:'clearPesquisaQuestions',
        payload:''
    },get(1)]
}

export const changePage = page => {
    return[{
        type: 'changePageQuestions',
        payload: page
    },get()]
}

export const search = () => {
    return [
        (dispatch, getState) => {
            const pesquisa = getState().flow.pesquisa || ''
            if(pesquisa){
                return dispatch({type:'changePageQuestions', payload:1})
            }
        }, get()
    ]
}