import styled from "styled-components";

export const Containner = styled.div`
    position: relative;
    width: 100%;
    height: ${props => props.height};
    

    /* FUNDO CAMPO/Grad.Azul Escuro */

    background: linear-gradient(180deg, #1D3A59 0%, #0A1D3A 100%);
    /* CAMPO/GRAD AZUL ESC */

    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
`;

export const Content = styled.div`
    position: absolute;
    width: 100%;
    height:100%;
    margin-top: 90px;
    /* margin-left: 10px; */
    /* top: 30px;
    left: 10px; */
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 13px;
    color: #FFFFFF;
    @media(min-width: 768px){
        font-size: 15px;
    }
`;

export const ContentTitle = styled.div`
    position: absolute;
    width: 70%;
    height: 100%;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 25px;
    text-transform: uppercase;
    margin-left: 10px;
    margin-top: 10px;
    /* top: 20px;
    left: 10px; */
    @media(min-width: 768px){
        font-size: 22px;
    }

    /* FUNDO BOTÃO/Azul Claro 2 */

    color: #00ABFF;
`;
export const ContentActions = styled.div`
    position: absolute;
    
    
    @media(max-width: 768px){
        left: calc(45% - 43.85px/2 + 124.92px);
        top: 10px;
        width: 100%;
        height: 30px;
    }
    @media(max-width: 300px){
        left: calc(30% - 43.85px/2 + 124.92px);
        width: 100%;
        height: 30px;
    }
    @media(min-width: 768px){
        margin-top: 2%;
        margin-left: 82%;
        width: 100%;
        height: 30px;
    }
    /* White/Sombra botões e Fundo */

    /* box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2); */
`

