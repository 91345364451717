import React from 'react'
import "react-toggle/style.css"
import { ButtonAction } from '../../../Button/style'
import RecipeReviewCard from '../../../RecipeReviewCard/RecipeReviewCard'

export default function Producers(props) {
    const producer = props.producers || []
    return producer.map(desc=>(
        <div key={desc.id}  className="col-md-4 mt-4">
            <RecipeReviewCard
                title={desc.name}
                actionRoute={props.action}
                actions={
                    props.action !== 'view' &&
                    <div className="text-center">
                        <ButtonAction>
                            
                            <button type="button" onClick={(e) => props.history.push(`/producer/${desc.id}/view`)} className="btn"><i style={{color: '#FFFF', width:'30px'}} className='fas fa-eye'></i></button>
                            <button type="button" onClick={(e) => props.history.push(`/producer/${desc.id}/edit`)} className="btn" ><i style={{color: '#FFFF', width:'30px'}} className='fas fa-edit'></i> </button>
                            <button type="button" className="btn" onClick={()=>props.onDelete(desc.id)} ><i style={{color: '#FFFF', width:'1px'}} className='fas fa-trash-alt'></i> </button>
                        </ButtonAction>

                    </div>
                
                }
                rel={false}
            >
                <p>
                    <span>
                        <a href="#/" onClick={(e) => {props.history.push(`/client/${desc.id_partner}/view`);e.preventDefault()}}>{`Parceiro: ${desc.partner}`}</a>
                    </span>

                </p>
                <p >{`Código: ${desc.code}`}</p>
                <p >{`Telefone Fixo: ${desc.landLine}`}</p>
                <p >{`Telefone Celular: ${desc.mobilePhone === null ? 'Não Possui' : desc.mobilePhone}`}</p>
            </RecipeReviewCard>
        </div>
    ))
    
}
