import React from 'react'
import { Link } from 'react-router-dom'
import "react-toggle/style.css"
import Toggle from 'react-toggle'
import Card from '../../../Card/Card'
import { ButtonAction } from '../../../Button/style'
import RecipeReviewCard from '../../../RecipeReviewCard/RecipeReviewCard'

export default function FlowPacksPartners(props) {
    const flowPackPartner = props.flowPacks.data || []
    const flows = props.flows || []
    return flowPackPartner.map((desc,i)=>(
        <div key={desc.id}  className="col-md-4 mt-4">
            <RecipeReviewCard
                title={desc.flow_pack}
                actionRoute={props.action}
                rel={
                    flows.length > 0 ?
                    flows.filter(fil => parseInt(fil.id_flow_pack) === parseInt(desc.id)).map((res,i) => (
                        <p>
                            <span>
                                <a href="#/" onClick={(e) => {props.history.push(`/flow/${res.id}/view`);e.preventDefault()}}>{`Fluxo ${i+1}: ${res.flow}`}</a>
                            </span>

                        </p>
                    )) : false

                }
                actions={
                    props.action !== 'view' &&
                    <div className="text-center">
                        <ButtonAction>
                            <button type="button" onClick={(e) => props.history.push(`/flow_pack/${desc.id}/view`)} className="btn"><i style={{color: '#FFFF', width:'30px'}} className='fas fa-eye'></i></button>
                            <button type="button" onClick={(e) => props.history.push(`/flow_pack/${desc.id}/edit`)} className="btn" ><i style={{color: '#FFFF', width:'30px'}} className='fas fa-edit'></i> </button>
                            <button type="button" className="btn" onClick={()=>props.onDelete(desc.id)} ><i style={{color: '#FFFF', width:'1px'}} className='fas fa-trash-alt'></i> </button>
                        </ButtonAction>

                    </div>
                
                }
            >
                {/* <p >{`Código: ${desc.code}`}</p>
                <p>
                    <span>
                        <a href="#/" onClick={(e) => {props.history.push(`/producer/${desc.id_producer}/view`);e.preventDefault()}}>{`Produtor: ${desc.producer}`}</a>
                    </span>

                </p> */}
                    
            </RecipeReviewCard>
        </div>
      
    ))
    
}
