import React from 'react'
import "react-toggle/style.css"
import { ButtonAction } from '../../../Button/style'
import RecipeReviewCard from '../../../RecipeReviewCard/RecipeReviewCard'


export default function Questions(props) {
    const question = props.questions || []
    return question.map(desc=>(
        <div className="col-md-4 mt-4" key={desc.id}>

            <RecipeReviewCard
                title={desc.title}
                actionRoute={props.action}
                actions={
                    props.action !== 'view' &&
                    <div className="text-center">
                        <ButtonAction>
                            
                            <button type="button" onClick={(e) => props.history.push(`/question/${desc.id}/view`)} className="btn"><i style={{color: '#FFFF', width:'30px'}} className='fas fa-eye'></i></button>
                            <button type="button" onClick={(e) => props.history.push(`/question/${desc.id}/edit`)} className="btn" ><i style={{color: '#FFFF', width:'30px'}} className='fas fa-edit'></i> </button>
                            <button type="button" className="btn" onClick={()=>props.onDelete(desc.id)} ><i style={{color: '#FFFF', width:'1px'}} className='fas fa-trash-alt'></i> </button>
                        </ButtonAction>

                    </div>
                
                }
                rel={false}
            >
                <p>
                    <span>
                        <a href="#/" onClick={(e) => {props.history.push(`/flow/${desc.id_flow}/view`);e.preventDefault()}}>{`Fluxo: ${desc.flow}`}</a>
                    </span>

                </p>
                <p>
                    <span>
                        <a href="#/" onClick={(e) => {props.history.push(`/topic/${desc.id_topic}/view`);e.preventDefault()}}>{`Tópico: ${desc.topic}`}</a>
                    </span>

                </p>
                <p>{`Peso: ${desc.weight}`}</p>
            </RecipeReviewCard>
        </div>
    ))
    
}
