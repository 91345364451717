import { combineReducers } from "redux";
import ActionReducer from "./ActionReducer";
import AuthReducer from './AuthReducer';
import ClientReducer from "./ClientReducer";
import FarmReducer from "./FarmReducer";
import FlowPackReducer from "./FlowPackReducer";
import FlowReducer from "./FlowReducer";
import LineReducer from "./LineReducer";
import ProducerReducer from "./ProducerReducer";
import QuestionReducer from "./QuestionReducer";
import TopicReducer from "./TopicReducer";
import UserReducer from "./UserReducer";




const AllReducers = combineReducers({
    auth: AuthReducer,
    flow: FlowReducer,
    topic: TopicReducer,
    client: ClientReducer,
    question: QuestionReducer,
    action: ActionReducer,
    flow_pack: FlowPackReducer,
    user: UserReducer,
    line: LineReducer,
    producer: ProducerReducer,
    farm: FarmReducer

  
})

export default AllReducers;