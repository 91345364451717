import { get_flow } from '../../../../Services/flow'

//Função que traz os dados do banco de dados
export const get = () =>{
    return[(dispatch,getState)=>{
        
        const order = getState().flow.order
        const th = getState().flow.th
        const qtd_per_page = getState().flow.qtd_per_page
        const pesquisa = getState().flow.pesquisa || ''       
        const page = getState().flow.page 

        get_flow(page,qtd_per_page,order,th,pesquisa)
        .then(resp=>dispatch({type:'Flows', payload:resp.data}))

    }]
}

//Função que ordena as colunas
export const order = (coluna) => {
    return [(dispatch,getState)=>{
        const asc = getState().flow.asc
      
        if(asc === true){
            dispatch({type:'GetFlows_Desc', desc:true, asc:false,order:'desc',th:coluna})
            
        }if(asc === false){
            dispatch({type:'GetFlows_Asc', asc:true, desc:false,order:'asc',th:coluna})
            
        }
     
    },get(1)]    
}

export const change_qtd_per_page = event => {
    return[{
        type:'qtd_per_page_Flows',
        payload:event.target.value
    },get(1)]
}

export const changePesquisa = event => {
    return[{
        type:'pesquisaFlows',
        payload:event.target.value
    }]
}

export const clearPesquisa = event => {
    return[{
        type:'clearPesquisaFlows',
        payload:''
    },get(1)]
}

export const changePage = page => {
    return[{
        type: 'changePageFlows',
        payload: page
    },get()]
}

export const search = () => {
    return [
        (dispatch, getState) => {
            const pesquisa = getState().flow.pesquisa || ''
            if(pesquisa){
                return dispatch({type:'changePageFlows', payload:1})
            }
        }, get()
    ]
}