const ActionReducer = (state = {}, actions)=>{
    switch(actions.type){

        case 'Actions':
            return{...state, actions:actions.payload}

        case 'GetActions_Desc':
            return{...state, desc:actions.desc, asc:actions.asc, order:actions.order, th:actions.th}

        case 'GetActions_Asc':
            return{...state, desc:actions.desc, asc:actions.asc, order:actions.order, th:actions.th}

        case 'qtd_per_page_Actions':
            return{...state, qtd_per_page:actions.payload}

        case 'pesquisaActions':
            return{...state, pesquisa:actions.payload}

        case 'clearPesquisaActions':
            return{...state, pesquisa:actions.payload}

        case 'changePageActions':
            return{...state, page:actions.payload}

        default:
            return state;
    }
}
export default ActionReducer;