import React from 'react'
import "react-toggle/style.css"
import Card from '../../../Card/Card'
import { ButtonAction } from '../../../Button/style'
import RecipeReviewCard from '../../../RecipeReviewCard/RecipeReviewCard'


export default function Lines(props) {
    const line = props.lines || []
    const farm = props.farms || []
    
    return line.map(desc=>(
        <div key={desc.id}  className="col-md-4 mt-4 mt-4">
            <RecipeReviewCard
                title={desc.name}
                actionRoute={props.action}
                rel={farm.length > 0 ?
                    farm.filter(fil => parseInt(fil.id_line) === parseInt(desc.id)).map((res,i) => (
                        <p>
                            <span>
                                <a href="#/" onClick={(e) => {props.history.push(`/farm/${res.id}/view`);e.preventDefault()}}>{`Fazenda ${i+1}: ${res.name}`}</a>
                            </span>

                        </p>
                    )) : false
                }
                actions={
                    props.action !== 'view' &&
                    <div className="text-center">
                        <ButtonAction>
                            <button type="button" onClick={(e) => props.history.push(`/line/${desc.id}/view`)} className="btn"><i style={{color: '#FFFF', width:'30px'}} className='fas fa-eye'></i></button>
                            <button type="button" onClick={(e) => props.history.push(`/line/${desc.id}/edit`)} className="btn" ><i style={{color: '#FFFF', width:'30px'}} className='fas fa-edit'></i> </button>
                            <button type="button" className="btn" onClick={()=>props.onDelete(desc.id)} ><i style={{color: '#FFFF', width:'1px'}} className='fas fa-trash-alt'></i> </button>
                        </ButtonAction>

                    </div>
                
                }
            >
            </RecipeReviewCard>


            {/* <Card 
                className="mt-3"
                height="150px" 
                title={desc.name}
            >
                <div className="ml-3">
                    <p>{`Nome: ${desc.name}`}</p>
                </div>
                <div className="text-center" style={{position: 'absolute', width: '100%', top: '18%'}}>
                    <ButtonAction>
                        <button type="button" onClick={(e) => props.history.push(`/line/${desc.id}/view`)} className="btn"><i style={{color: '#FFFF', width:'30px'}} className='fas fa-eye'></i></button>
                        <button type="button" onClick={(e) => props.history.push(`/line/${desc.id}/edit`)} className="btn" ><i style={{color: '#FFFF', width:'30px'}} className='fas fa-edit'></i> </button>
                        <button type="button" className="btn" onClick={()=>props.onDelete(desc.id)} ><i style={{color: '#FFFF', width:'1px'}} className='fas fa-trash-alt'></i> </button>
                    </ButtonAction>

                </div>
            </Card> */}
        </div>

    ))
    
}
