import React, { useState, useEffect, useRef } from 'react'
import Header from '../../../Header/Header'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Sidebar from '../../../Sidebar/Sidebar'
import StylePage from '../../../Style Page/StylePage'
import Form from '../../../Forms/Form'
import TextArea from '../../../Forms/TextArea'
import Input from '../../../Forms/Input'
import NumberFormat from 'react-number-format'
import Alert from '../../../Alerts/Alert'
import Footer from '../../../Footer/Footer'
import ReactLoading from 'react-loading'
import { Content } from '../../../LoadingStyle/StyleLoading'
import Select from 'react-select'
import { Link, useHistory } from "react-router-dom"
import { post_question, get_view_question, put_question, get_resource } from '../../../Services/question'
import InputMaterial from '../../../Forms/InputMaterial';
import SelectMaterial from '../../../Forms/SelectMaterial';
import RadioMaterial from '../../../Forms/RadioMaterial';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { ButtonContainner } from '../../../Button/style';





export default function Question(props) {

    let history = useHistory();
    const [ loading, setLoading ] = useState(false)
    const [ description, setDescription ] = useState('')
    const [ title, setTitle ] = useState('')
    const [ action, setAction ] = useState('')
    const [ rightAnswer, setRightAnswer ] = useState("Não")
    const [ requiresDetailing, setRequiresDetailing ] = useState("Não")
    const [ requiresPhoto, setRequiresPhoto ] = useState("Não")
    const [ weight, setWeight ] = useState('')
    const [ topics, setTopics ] = useState([])
    const [ idTopic, setIdTopic ] = useState([])
    const [ error, setError ] = useState([])
    const [ success, setSuccess ] = useState('')
    const [ alert, setAlert ] = useState('')
    const selectTopicRef = useRef(null)

    useEffect(() => {
        getResource()
    }, [])

    useEffect(() => {
        viewPerId()
    }, [])
  
    function getResource(){
        get_resource()
        .then(resp => {
            setTopics(resp.data.topic.map(desc => ({
                value: parseInt(desc.id),
                title: desc.name
            })))
        })
    }

    const useStyles = makeStyles({
        root: {
            '&:hover': {
                backgroundColor: 'transparent',
            
            },
            
           
            color:'#FFFF'
            
        },
        icon: {
            borderRadius: '50%',
            width: 16,
            height: 16,
            boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
            backgroundColor: '#f5f8fa',
            backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
            '$root.Mui-focusVisible &': {
                outline: '2px auto rgba(19,124,189,.6)',
                outlineOffset: 2,
            },
            'input:hover ~ &': {
                backgroundColor: '#ebf1f5',
            },
            'input:disabled ~ &': {
                boxShadow: 'none',
                background: 'rgba(206,217,224,.5)',
                color: '#FFFF'
            },
        },
        checkedIcon: {
            backgroundColor: '#137cbd',
            backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
            '&:before': {
                display: 'block',
                width: 16,
                height: 16,
                backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
                content: '""',
            },
            'input:hover ~ &': {
                backgroundColor: '#106ba3',
            },
        },
      });
      
    // Inspired by blueprintjs
    function StyledRadio(props) {
        const classes = useStyles();
        return (
            <Radio
                className={classes.root}
                disableRipple
                color="default"
                
                checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                icon={<span className={classes.icon} />}
                {...props}
            />
        );
      }

    function viewPerId(){
        props.match.params.action &&
        get_view_question(props.match.params.id)
        .then(resp => {
            setDescription(resp.data[0].description)
            setIdTopic({value: resp.data[0].id_topic, title: resp.data[0].topic})
            setWeight(resp.data[0].weight)
            setTitle(resp.data[0].title)
            setRequiresDetailing(resp.data[0].require_detailing === 1 ? 'Sim' : 'Não')
            setRequiresPhoto(resp.data[0].require_photo === 1 ? 'Sim' : 'Não')
            setRightAnswer(resp.data[0].right_answer === 1 ? 'Sim' : 'Não')
            setAction(resp.data[0].action)
        })
    }

    const defaultProps = {
        options: topics,
        getOptionLabel: (option) => option.title,
        value:(option) => option.value
    };
  
    

    const handleForm=(e)=>{
        e.preventDefault();
        setLoading(true)
        const data = {
            description: description,
            idTopic: parseInt(idTopic.value),
            weight: weight,
            title: title,
            rightAnswer: rightAnswer === 'Sim' ? 1 : 0,
            requiresDetailing: requiresDetailing === 'Sim' ? 1 : 0,
            requiresPhoto: 0,
            action: action
        }

        if(props.match.params.action === 'edit'){
            const id = props.match.params.id
            put_question(id,data)
            .then(resp=> {
                setLoading(false)
                setAlert('')
                setError([])
                setSuccess(resp.data.success)    
            })
            .then(resp => setTimeout(() => {
                window.location.reload()
            },2500))
            .catch(e => {
                setLoading(false)
                setSuccess('')
                setError(e.response.data.error)
                setAlert(e.response.data.errorSubmit)  
            })              


        }else{
            post_question(data)
            .then(resp=> {
                setLoading(false)
                setAlert('')
                setError([])
                setSuccess(resp.data.success)    
            })
            .then(resp => setTimeout(() => {
                window.location.reload()
            },2500))
            .catch(e => {
                setLoading(false)
                setSuccess('')
                setError(e.response.data.error)
                setAlert(e.response.data.errorSubmit)  
            })              

        }
    }

    return (
        <div className="wrapper">
            <Sidebar />
            <Header />
            <StylePage>
                {loading === true ? (
                    <Content>
                        <ReactLoading type="spinningBubbles" color="blue" height={200} width={100} />
                    </Content>
                    
                ):('')}
                {alert ? (
                    <Alert type="danger">
                        {alert}
                    </Alert>
                ):('')}
                {success ? (
                    <Alert type="success">
                        {success}
                    </Alert>
                ):('')}
                <div style={{display: loading === true ? ('none'):('block')}}>
                    <section className="content-header">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <h2 style={{color: '#00ABFF', textAlign: 'center'}}>{props.match.params.action === 'edit' ? "Edição de Perguntas" : props.match.params.action === 'view' ? "Visualização de Perguntas" : "Cadastro de Perguntas"}</h2>
                                </div>
                            </div>
                        </div>{/* /.container-fluid */}
                    </section>
                    <Form onSubmit={handleForm}>
                        <div className="row">
                            <div className="col-md-4">
                                <InputMaterial 
                                    label="Título"
                                    id="titleQuestion"
                                    name="titleQuestion"
                                    value={title}
                                    onChange={(e)=>setTitle(e.target.value)} 
                                    required={true}
                                    disabled={props.match.params.action === 'view' ? true : false}
                                />
                            </div>
                            <div className="col-md-4">
                                <SelectMaterial 
                                    label="Tópico"
                                    id="topicQuestion"
                                    name="topicQuestion"
                                    value={idTopic}
                                    onChange={(event, newValue) => {
                                        setIdTopic(newValue);
                                    }}
                                    user={true}
                                    defaultProps={defaultProps}
                                    required={true}
                                    disabled={props.match.params.action === 'view' ? true : false}
                                />
                            </div>
                            <div className="col-md-4">
                                <NumberFormat  
                                    label="Peso" 
                                    name="weight" 
                                    fixedDecimalScale={true}
                                    decimalScale={0}
                                    thousandSeparator={"."} 
                                    decimalSeparator={","}
                                    placeholder=""
                                    customInput={InputMaterial}
                                    allowNegative={false}
                                    onChange={(e)=>setWeight(parseFloat(e.target.value))} 
                                    value={weight}
                                    disabled={props.match.params.action === 'view' ? (true):(false)}
                                    required={true} 
                                    isAllowed={(values) => { const {floatValue,formattedValue} = values;  return formattedValue === "" || floatValue > 0 && floatValue <= 10;}}                         
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 mt-4">
                                <TextArea 
                                    styleLabel={{
                                        color: '#BEBEBE',
                                        fontSize: '12px',
                                        marginBottom: '15px'
                                    }}
                                    style={{
                                        background: 'rgb(0, 60, 80)',
                                        
                                        /* FUNDO CAMPO DIGITAÇÃO/AZUL */
                                        color: '#FFFF',
                                        border: '2px solid #262D40',
                                        /* CAMPOS DIGITÁVEIS AZUL ESC */

                                        boxShadow: 'inset 0px -1px 1px rgba(255, 255, 255, 0.15), inset 1px 1px 5px rgba(0, 0, 0, 0.4), inset -3px -2px 3px rgba(255, 255, 255, 0.15)',
                                        
                                    }}
                                    label="Descrição *" 
                                    value={description} 
                                    onChange={(e)=>setDescription(e.target.value)} 
                                    icon="fas fa-pencil-alt" 
                                    name="name" 
                                    type="text" 
                                    rows={6}
                                    placeholder="Digite a pergunta" 
                                    required={true}
                                    disabled={props.match.params.action === 'view' ? true : false}
                                />
                                {error ? (<p style={{color:'red'}}>{error.name}</p>):('')}
                            </div> 
                        </div>
                        <div className="row mt-4 ml-2">
                            <div className="col-md-6">
                                <RadioMaterial
                                    row 
                                    label="Resposta Correta"
                                    ariaLabel="rightAnswer" 
                                    name="rightAnswer" 
                                    value={rightAnswer}
                                    styleLabel={{
                                        color: '#BEBEBE',
                                        fontSize: '12px',
                                        marginBottom: '15px'
                                    }} 
                                    onChange={(e)=>setRightAnswer(e.target.value)}
                                    disabled={props.match.params.action === 'view' ? true : false}
                                >
                                    <FormControlLabel value="Sim" control={<StyledRadio />} label="Sim" />
                                    <FormControlLabel value="Não" control={<StyledRadio />} label="Não" />
                                </RadioMaterial>
                            </div>
                            <div className="col-md-6">
                                <RadioMaterial
                                    row 
                                    label="Exige Detalhamento"
                                    row 
                                    aria-label="requiresDetailing" 
                                    name="requiresDetailing" 
                                    value={requiresDetailing} 
                                    styleLabel={{
                                        color: '#BEBEBE',
                                        fontSize: '12px',
                                        marginBottom: '15px'
                                    }} 
                                    onChange={(e)=>setRequiresDetailing(e.target.value)}
                                    disabled={props.match.params.action === 'view' ? true : false}
                                >
                                    <FormControlLabel value="Sim" control={<StyledRadio />} label="Sim" />
                                    <FormControlLabel value="Não" control={<StyledRadio />} label="Não" />
                                </RadioMaterial>
                            </div>
                            {/* <div className="col-md-4">
                                <RadioMaterial
                                    row 
                                    label="Exige Fotos"
                                    row aria-label="requiresPhoto" 
                                    name="requiresPhoto" 
                                    value={requiresPhoto} 
                                    styleLabel={{
                                        color: '#BEBEBE',
                                        fontSize: '12px',
                                        marginBottom: '15px'
                                    }} 
                                    onChange={(e)=>setRequiresPhoto(e.target.value)}
                                    disabled={props.match.params.action === 'view' ? true : false}
                                >
                                    <FormControlLabel value="Sim" control={<StyledRadio />} label="Sim" />
                                    <FormControlLabel value="Não" control={<StyledRadio />} label="Não" />
                                </RadioMaterial>
                            </div> */}
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <InputMaterial 
                                    label="Ação"
                                    id="nameAction"
                                    name="nameAction"
                                    value={action}
                                    onChange={(e)=>setAction(e.target.value)} 
                                    required={true}
                                    disabled={props.match.params.action === 'view' ? true : false}
                                />
                            </div>
                        </div>
                        <br></br>
                        <div className="text-right">
                            <button style={{marginRight:'10px'}} type="button" className="btn btn-secondary" onClick={()=>history.goBack()}>Voltar</button>
                            {props.match.params.action === 'view' ? (
                                 <div style={{display: 'inline-block'}}>
                                    <ButtonContainner>
                                        <button onClick={(e) => {props.history.push(`/question/${props.match.params.id}/edit`);e.preventDefault()}} className="btn btn-primary">Editar</button>
                                    </ButtonContainner>
                                </div>
                            ) : (
                                <div style={{display: 'inline-block'}}>
                                    <ButtonContainner>
                                        <button disabled={props.match.params.action === 'view' ? true : false} type="submit" className="btn btn-primary">Salvar</button>                           
                                    </ButtonContainner>
                                </div>
                            )}
                        </div>
                       
                        {/* <div className="row">
                            <div className="col-md-4">
                                <Input 
                                    label="Título"
                                    value={title}
                                    onChange={(e)=>setTitle(e.target.value)} 
                                    icon="fas fa-paragraph"
                                    required={true}
                                    disabled={props.match.params.action === 'view' ? true : false}                               
                                />
                            </div>
                            <div className="col-md-4">
                                <label>Tópico</label>
                                <Select 
                                    value={idTopic}
                                    ref={selectTopicRef}
                                    label="Single select"
                                    options={topics}
                                    onChange={(e)=>setIdTopic(e)}
                                    isDisabled={props.match.params.action === 'view' ? true : false}
    
                                />
                                {idTopic.length <= 0 && 
                                    <input
                                        tabIndex={-1}
                                        autoComplete="off"
                                        style={{
                                            opacity: 0,
                                            width: "100%",
                                            height: 0,
                                            position: "absolute"
                                        }}
                                        defaultValue={idTopic}
                                        onFocus={() => selectTopicRef.current.focus()}
                                        required={true}
                                    />
                                }
                                {error ? (<p style={{color:'red'}}>{error.number}</p>):('')}                          
                            </div>
                            <div className="col-md-4">
                                <NumberFormat  
                                    label="Peso" 
                                    icon="fas fa-balance-scale-right"
                                    name="weight" 
                                    fixedDecimalScale={true}
                                    decimalScale={0}
                                    thousandSeparator={"."} 
                                    decimalSeparator={","}
                                    placeholder=""
                                    customInput={Input}
                                    allowNegative={false}
                                    onChange={(e)=>setWeight(parseFloat(e.target.value))} 
                                    value={weight}
                                    disabled={props.match.params.action === 'view' ? (true):(false)}
                                    required={true} 
                                    isAllowed={(values) => { const {floatValue,formattedValue} = values;  return formattedValue === "" || floatValue > 0 && floatValue <= 10;}}                         
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <TextArea 
                                    label="Descrição" 
                                    value={description} 
                                    onChange={(e)=>setDescription(e.target.value)} 
                                    icon="fas fa-pencil-alt" 
                                    name="name" 
                                    type="text" 
                                    rows={6}
                                    placeholder="Digite a pergunta" 
                                    required={true}
                                    disabled={props.match.params.action === 'view' ? true : false}
                                />
                                {error ? (<p style={{color:'red'}}>{error.name}</p>):('')}
                            </div> 
                        </div>
                        <div className="row mt-3">
                            <div className="col-md-4">
                                <FormControl disabled={props.match.params.action === 'view' ? true : false} component="fieldset">
                                    <FormLabel component="legend">Resposta Correta</FormLabel>
                                    <RadioGroup row aria-label="rightAnswer" name="rightAnswer" value={rightAnswer} onChange={(e)=>setRightAnswer(e.target.value)}>
                                        <FormControlLabel value="Sim" control={<Radio />} label="Sim" />
                                        <FormControlLabel value="Não" control={<Radio />} label="Não" />
                                    </RadioGroup>
                                </FormControl>
                            </div>
                            <div className="col-md-4">
                                <FormControl disabled={props.match.params.action === 'view' ? true : false} component="fieldset">
                                    <FormLabel component="legend">Exige Detalhamento</FormLabel>
                                    <RadioGroup row aria-label="requiresDetailing" name="requiresDetailing" value={requiresDetailing} onChange={(e)=>setRequiresDetailing(e.target.value)}>
                                        <FormControlLabel value="Sim" control={<Radio />} label="Sim" />
                                        <FormControlLabel value="Não" control={<Radio />} label="Não" />
                                    </RadioGroup>
                                </FormControl>
                            </div>
                            <div className="col-md-4">
                                <FormControl disabled={props.match.params.action === 'view' ? true : false} component="fieldset">
                                    <FormLabel component="legend">Exige Foto</FormLabel>
                                    <RadioGroup row aria-label="requiresPhoto" name="requiresPhoto" value={requiresPhoto} onChange={(e)=>setRequiresPhoto(e.target.value)}>
                                        <FormControlLabel value="Sim" control={<Radio />} label="Sim" />
                                        <FormControlLabel value="Não" control={<Radio />} label="Não" />
                                    </RadioGroup>
                                </FormControl>
                            </div>
                           
                        </div>
                        <br></br>
                        <div className="text-right">
                            <button style={{marginRight:'10px'}} type="button" className="btn btn-secondary" onClick={()=>history.goBack()}>Voltar</button>
                            {props.match.params.action === 'view' ? (
                                <Link to={`/question/${props.match.params.id}/edit`} className="btn btn-primary">Editar</Link>
                            ) : (
                                <button disabled={props.match.params.action === 'view' ? true : false} type="submit" className="btn btn-primary">Salvar</button>                           
                            )}
                        </div>         */}
                    </Form>
                </div>   
            </StylePage>
            <Footer />
        </div>
    )
}
