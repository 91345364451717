const LineReducer = (state = {}, actions)=>{
    switch(actions.type){

        case 'Lines':
            return{...state, lines:actions.payload, farms:actions.farms}

        case 'GetLines_Desc':
            return{...state, desc:actions.desc, asc:actions.asc, order:actions.order, th:actions.th}

        case 'GetLines_Asc':
            return{...state, desc:actions.desc, asc:actions.asc, order:actions.order, th:actions.th}

        case 'qtd_per_page_Lines':
            return{...state, qtd_per_page:actions.payload}

        case 'pesquisaLines':
            return{...state, pesquisa:actions.payload}

        case 'clearPesquisaLines':
            return{...state, pesquisa:actions.payload}

        case 'changePageLines':
            return{...state, page:actions.payload}

        default:
            return state;
    }
}
export default LineReducer;