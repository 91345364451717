import React from 'react'
import TextField from '@material-ui/core/TextField';
import {
    fade,
    ThemeProvider,
    withStyles,
    makeStyles,
    createMuiTheme,
  } from '@material-ui/core/styles';

export default function InputMaterial(props) {

    const useStyles = makeStyles((theme) => ({
        root: {
            display: 'flex',
            flexWrap: 'wrap',
            
           
        },
        margin: {
            '& label.Mui-focused': {
                color: '#BEBEBE',
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: '#FFFF',
                color: '#FFFF'
            },
            '& .MuiInput-underline:before': {
                borderBottom: '1px solid rgba(255, 255, 255, 0.42)'
            },
            '& .MuiInputBase-input':{
                color: '#FFFF'
            },
            '& .MuiFormLabel-root':{
                
                color: '#BEBEBE',
                fontSize: '12px',
                marginBottom: '20px'
            },
            margin: theme.spacing(1),
            width: '100%'
        },
    }));

    const classes = useStyles();

    return (
        <TextField 
            className={classes.margin} 
            id={props.id} 
            name={props.name}
            label={props.label} 
            required={props.required}
            onChange={props.onChange}
            value={props.value}
            type={props.type}
            disabled={props.disabled}
        />
    )
}
