const ProducerReducer = (state = {}, actions)=>{
    switch(actions.type){

        case 'Producers':
            return{...state, producers:actions.payload}

        case 'GetProducers_Desc':
            return{...state, desc:actions.desc, asc:actions.asc, order:actions.order, th:actions.th}

        case 'GetProducers_Asc':
            return{...state, desc:actions.desc, asc:actions.asc, order:actions.order, th:actions.th}

        case 'qtd_per_page_Producers':
            return{...state, qtd_per_page:actions.payload}

        case 'pesquisaProducers':
            return{...state, pesquisa:actions.payload}

        case 'clearPesquisaProducers':
            return{...state, pesquisa:actions.payload}

        case 'changePageProducers':
            return{...state, page:actions.payload}

        default:
            return state;
    }
}
export default ProducerReducer;