import React, { useState, useEffect } from 'react'
import FlowPacksFarms from './FlowPacksFarms'

export function ListFlowPackFarm(props) {
    let flowPacksFarms = props.flowPacksFarms || []
    return (
        <section className="content">
            <div className="card-body">
                <div className="row mt-3">
                    <FlowPacksFarms action={props.action} history={props.history} changeStatus={props.changeStatusActive} flowPacks={flowPacksFarms} onDelete={props.onDelete} />
                </div>
            </div>
        </section>
    )
}

