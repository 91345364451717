import { app } from './Api'

const get_view_action = async (id) => {
    return app.get(`action/${id}`)
}

const get_action = async (page,qtd_per_page,order,th,pesquisa) => {
    return app.get(`action?page=${page}&qtd=${qtd_per_page}&order=${order}&th=${th}&pesquisa=${pesquisa}`)
}

const get_resource = async () => {
    return app.get(`get_resource_action`)
}

const post_action = async (data) => {
    return app.post(`action`, data)
}

const changeStatus =  async (id,data) => {
    return app.put(`action/change_status/${id}`,data)
}

const put_action = async (id,data) => {
    return app.put(`action/${id}`,data)
}

const del_action = async (id) => {
    return app.delete(`action/${id}`)
}


export { get_view_action, get_action, changeStatus, post_action, put_action, del_action, get_resource }