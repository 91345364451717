import { app } from './Api'

const getViewProducer = async (id) => {
    return app.get(`producer/${id}`)
}

const getProducers = async (page,qtd_per_page,order,th,pesquisa) => {
    return app.get(`producer?page=${page}&qtd=${qtd_per_page}&order=${order}&th=${th}&pesquisa=${pesquisa}`)
}

const postProducer = async (data) => {
    return app.post(`producer`, data)
}

const changeStatus =  async (id,data) => {
    return app.put(`producer/change_status/${id}`,data)
}

const putProducer = async (id,data) => {
    return app.put(`producer/${id}`,data)
}

const delProducer = async (id) => {
    return app.delete(`producer/${id}`)
}

const getResource = async () => {
    return app.get(`get_partner_resource`)
}


export { getViewProducer, getProducers, changeStatus, postProducer, putProducer, delProducer, getResource }