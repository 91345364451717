export const colourOptions = [
    { id: '#00B8D9', title: 'Azul Oceano', color: '#00B8D9', isFixed: true },
    { id: '#0052CC', title: 'Azul', color: '#0052CC'},
    { id: '#5243AA', title: 'Roxo', color: '#5243AA' },
    { id: '#FF5630', title: 'Vermelho', color: '#FF5630', isFixed: true },
    { id: '#FF8B00', title: 'Laranja', color: '#FF8B00' },
    { id: '#FFC400', title: 'Amarelo', color: '#FFC400' },
    { id: '#36B37E', title: 'Verde', color: '#36B37E' },
    { id: '#00875A', title: 'Verde Floresta', color: '#00875A' },
    { id: '#666666', title: 'Prata', color: '#666666' },
];