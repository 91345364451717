import { app } from './Api'

const get_view_topic = async (id) => {
    return app.get(`topic/${id}`)
}

const get_resource = async () => {
    return app.get(`get_resource_topic`)
}

const get_topic = async (page,qtd_per_page,order,th,pesquisa,idFlowFilter) => {
    return app.get(`topic?page=${page}&qtd=${qtd_per_page}&order=${order}&th=${th}&pesquisa=${pesquisa}&idFlowFilter=${idFlowFilter}`)
}

const post_topic = async (data) => {
    return app.post(`topic`, data)
}

const changeStatus =  async (id,data) => {
    return app.put(`topic/change_status/${id}`,data)
}

const put_topic = async (id,data) => {
    return app.put(`topic/${id}`,data)
}

const del_topic = async (id) => {
    return app.delete(`topic/${id}`)
}


export { get_view_topic, get_topic, changeStatus, post_topic, put_topic, del_topic, get_resource }