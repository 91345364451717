import styled from "styled-components";


export const Wrapper = styled.div`
    @media(min-width: 768px){

        width: 72%;
        height: 100%;
        background-image: url("https://checkagroapi.rehagro.com.br/storage/icons/Vaca.jpeg");
        /* background-size: cover !important;  */
        position: absolute;
        float: left;
        background-position: center center;
        background-repeat: no-repeat;
    }
    @media(max-width: 768px){

        width: 100%;
        height: 100%;
        background-image: url("https://checkagroapi.rehagro.com.br/storage/icons/Vaca.jpeg");
        background-size: cover !important; 
        position: absolute;
        background-position: center center;
        background-repeat: no-repeat;
    }

`;



export const LogoStyle = styled.div`
    /* position:relative;
    left:50%;
    margin-left:-65px; */
    @media(min-width: 768px){
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-content: center;
        /* margin-top: 30px; */

    }
    @media(max-width: 768px){
        position:absolute;
        left: 50%;
        margin-left: -20%;
        margin-top: 10px;
             
    }
    
    
    
`;

export const Content = styled.div`
    @media(min-width: 768px){
        float: right;
        margin-right: 2%;
        margin-top: 10%;
        width: 25%;
    }
    @media(max-width: 768px){

        width: 80%;
        left: 30%;
        top: 30%;
        margin-left: -17%;
        position: absolute;

        

    }
    
`;


export const Container = styled.div`
    width: 100%;
    height: 100%;
`;

export const ContentRemember = styled.div`
    margin-top: 60px;
    a{
        font-weight: bold;
        color: #000000;
    }
    a:hover{
        font-weight: bold;
        color: #0070BA;
    }
`;

export const ButtonContainner = styled.div`
    button{
        background: linear-gradient(180deg, #00E6FF 0%, #0070BA 109.18%);
    }
`;



