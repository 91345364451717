import React from 'react'
import { Link } from 'react-router-dom'
import "react-toggle/style.css"
import Toggle from 'react-toggle'
import Card from '../../../Card/Card'
import { ButtonAction } from '../../../Button/style'
import RecipeReviewCard from '../../../RecipeReviewCard/RecipeReviewCard'

export default function Users(props) {
    const user = props.users || []
    return user.map((desc,i)=>(
        <div key={desc.id}  className="col-md-4 mt-4">
            <RecipeReviewCard
                title={desc.name}
                actionRoute={props.action}
                rel={false}
                actionDisabled={
                    <Toggle
                        
                        id={`${desc.id}`}
                        checked={desc.ativo === 1 ?(true):(false)}
                        onChange={props.changeStatus} 
                    />
                }
                actions={
                    props.action !== 'view' &&
                    <div className="text-center">
                        <ButtonAction>
                            <button type="button" onClick={(e) => props.history.push(`/user/${desc.id}/view`)} className="btn"><i style={{color: '#FFFF', width:'30px'}} className='fas fa-eye'></i></button>
                            <button type="button" onClick={(e) => props.history.push(`/user/${desc.id}/edit`)} className="btn" ><i style={{color: '#FFFF', width:'30px'}} className='fas fa-edit'></i> </button>
                            <button type="button" className="btn" onClick={()=>props.onDelete(desc.id)} ><i style={{color: '#FFFF', width:'1px'}} className='fas fa-trash-alt'></i> </button>
                        </ButtonAction>

                    </div>
                
                }
            >
                <p>{`Email: ${desc.email}`}</p>
                <p>{`Perfil: ${desc.role}`}</p>
                    
            </RecipeReviewCard>
           
           
           
           
           
           
           
            {/* <Card 
                className="mt-3"
                height="200px" 
                title={desc.name}
                changeStatus={
                
                <Toggle
                        
                    id={`${desc.id}`}
                    checked={desc.ativo === 1 ?(true):(false)}
                    onChange={props.changeStatus} 
                />}
            >
                <div className="ml-3">
                    <p>{`Email: ${desc.email}`}</p>
                    <p>{`Perfil: ${desc.role}`}</p>
                </div>
                <div className="text-center mt-4">
                    <ButtonAction>
                        <button type="button" onClick={(e) => props.history.push(`/user/${desc.id}/view`)} className="btn"><i style={{color: '#FFFF', width:'30px'}} className='fas fa-eye'></i></button>
                        <button type="button" onClick={(e) => props.history.push(`/user/${desc.id}/edit`)} className="btn" ><i style={{color: '#FFFF', width:'30px'}} className='fas fa-edit'></i> </button>
                        <button type="button" className="btn" onClick={()=>props.onDelete(desc.id)} ><i style={{color: '#FFFF', width:'1px'}} className='fas fa-trash-alt'></i> </button>
                    </ButtonAction>

                </div>
            </Card> */}
        </div>
        // <tr key={desc.id}>
        //     <td>
        //         <Toggle
                    
        //             id={`${desc.id}`}
        //             checked={desc.ativo === 1 ?(true):(false)}
        //             onChange={changeStatus} 
        //         />
        //             {/* <label htmlFor='cheese-status'>Adjacent label tag</label> */}

        //     </td>
        //     <td>{desc.name}</td>
        //     <td>{desc.email}</td>
        //     <td>{desc.role}</td>
        //     <td>
        //         <ul className="nav">
        //             <li className="nav-item dropdown">
        //                 <a className="nav-link" data-toggle="dropdown" href="#/">
        //                     <i className="fas fa-ellipsis-h" />             
        //                 </a>
        //                 <div className='dropdown-menu dropdown-menu-right'>                  
        //                     <Link to={`/user/${desc.id}/view`} className='dropdown-item'><i className='fas fa-eye'></i> Detalhes</Link>
        //                     <Link to={`/user/${desc.id}/edit`} className='dropdown-item' ><i className='fas fa-edit'></i> Editar</Link>
        //                     <Link to="#/" className='dropdown-item' onClick={()=>onDelete(desc.id)} ><i className='fas fa-trash-alt'></i> Excluir</Link>                                
        //                 </div>
        //             </li>
        //         </ul>		
        //     </td>                
        // </tr>
    ))
    
}
