import { createStore,applyMiddleware } from "redux";
import AllReducers from './reducers/AllReducers'
import promise from 'redux-promise'
import multi from 'redux-multi'
import thunk from 'redux-thunk'



//Função que pega a data atual como default ;

    var data = new Date();
    var dia = data.getDate();
    var mes = data.getMonth();
    var ano = data.getFullYear();

    if(mes + 1 < 10){
        var compMes = 0
    }else{
        compMes = ''
    }
    
    if(dia<10){
        var compDia = 0;
    }if(dia>=10){
        compDia = '';
    }            
    var str_date =ano + '-' + compMes+(mes+1) + '-' + compDia+(dia) 
    


const initialStates = {
    auth: {
        loggedIn: false,
        user:{},
        
        
    },
    flow_pack:{
        flowPacks:[],
        asc:true,
        desc:false,
        order:'asc',
        th:'flow_pack.name',
        qtd_per_page:9,
        page:1
    },
    flow:{
        flows:[],
        asc:true,
        desc:false,
        order:'asc',
        th:'flow.name',
        qtd_per_page:9,
        page:1
    },
    line:{
        farms:[],
        lines:[],
        asc:true,
        desc:false,
        order:'asc',
        th:'line.name',
        qtd_per_page:9,
        page:1
    },
    topic:{
        topics:[],
        asc:true,
        desc:false,
        order:'asc',
        th:'topic.name',
        qtd_per_page:9,
        page:1,
        idFlowFilter:{}
    },
    action:{
        actions:[],
        asc:true,
        desc:false,
        order:'asc',
        th:'title',
        qtd_per_page:9,
        page:1
    },
    producer:{
        producers:[],
        asc:true,
        desc:false,
        order:'asc',
        th:'producer.name',
        qtd_per_page:9,
        page:1
    },
    farm:{
        farms:[],
        lines:[],
        asc:true,
        desc:false,
        order:'asc',
        th:'farm.name',
        qtd_per_page:9,
        page:1
    },
    client:{
        customers:[],
        asc:true,
        desc:false,
        order:'asc',
        th:'client.name',
        qtd_per_page:9,
        page:1
    },
    question:{
        questions:[],
        asc:true,
        desc:false,
        order:'asc',
        th:'question.title',
        qtd_per_page:9,
        page:1,
        idTopicFilter:{},
        idFlowFilter:{},
    },
    user:{
        users:[],
        asc:true,
        desc:false,
        order:'asc',
        th:'user.name',
        qtd_per_page:9,
        page:1
    },
  
}
const store = applyMiddleware(thunk,multi,promise)(createStore)(AllReducers,initialStates,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)

export default store;