import React from 'react'
import "react-toggle/style.css"
import Toggle from 'react-toggle'
import Card from '../../../Card/Card'
import { ButtonAction } from '../../../Button/style'
import MediaCard from '../../../MediaCard/MediaCard'
import RecipeReviewCard from '../../../RecipeReviewCard/RecipeReviewCard'

export default function FarmsLine(props) {
    console.log(props.action)
    const farm = props.farms || []
    const line = props.lines || []
    return farm.map(desc=>(
        <div key={desc.id}  className="col-md-4">
            <RecipeReviewCard
                title={desc.name}
                actionRoute={props.action}
                actions={
                    props.action !== 'view' &&
                    <div className="text-center">
                        <ButtonAction>
                            <button type="button" onClick={(e) => props.history.push(`/farm/${desc.id}/view`)} className="btn"><i style={{color: '#FFFF', width:'30px'}} className='fas fa-eye'></i></button>
                            <button type="button" onClick={(e) => props.history.push(`/farm/${desc.id}/edit`)} className="btn" ><i style={{color: '#FFFF', width:'30px'}} className='fas fa-edit'></i> </button>
                            <button type="button" className="btn" onClick={()=>props.onDelete(desc.id)} ><i style={{color: '#FFFF', width:'1px'}} className='fas fa-trash-alt'></i> </button>
                        </ButtonAction>

                    </div>
                
                }
                rel={line.length > 0 ?
                    line.filter(fil => parseInt(fil.id_farm) === parseInt(desc.id)).map((res,i) => (
                        <p>
                            <span>
                                <a href="#/" onClick={(e) => {props.history.push(`/line/${res.id_line}/view`);e.preventDefault()}}>{`Linha ${i+1}: ${res.name}`}</a>
                            </span>

                        </p>
                    )) : false
                }
            >
            <p >{`Código: ${desc.code}`}</p>
                    <p >{`Telefone Fixo: ${desc.landLine}`}</p>
                    <p >{`Telefone Celular: ${desc.mobilePhone === null ? 'Não Possui' : desc.mobilePhone}`}</p>
                    <p >{`Telefone Fixo: ${desc.landLine}`}</p>
                    <p>{`Conforme IN76/77 ? : ${desc.according_in7677 === 1 ? 'Sim' : 'Não'}`}</p>
                    <p>
                        <span>
                            <a href="#/" onClick={(e) => {props.history.push(`/producer/${desc.id_producer}/view`);e.preventDefault()}}>{`Produtor: ${desc.producer}`}</a>
                        </span>

                    </p>
                    
            </RecipeReviewCard>
            {/* <Card 
                className="mt-3"
                height={props.action === 'view' ? '250px' : '380px'} 
                title={desc.name}
            >
                <div className="ml-3">
                    <p >{`Código: ${desc.code}`}</p>
                    <p >{`Telefone Fixo: ${desc.landLine}`}</p>
                    <p >{`Telefone Celular: ${desc.mobilePhone === null ? 'Não Possui' : desc.mobilePhone}`}</p>
                    <p >{`Telefone Fixo: ${desc.landLine}`}</p>
                    <p>{`Conforme IN76/77 ? : ${desc.according_in7677 === 1 ? 'Sim' : 'Não'}`}</p>
                    <p>
                        <span>
                            <a href="#/" onClick={(e) => {props.history.push(`/producer/${desc.id_producer}/view`);e.preventDefault()}}>{`Produtor: ${desc.producer}`}</a>
                        </span>

                    </p>
                    {line.filter(fil => parseInt(fil.id_farm) === parseInt(desc.id)).map((res,i) => (
                        <p>
                            <span>
                                <a href="#/" onClick={(e) => {props.history.push(`/line/${res.id_line}/view`);e.preventDefault()}}>{`Linha ${i+1}: ${res.name}`}</a>
                            </span>

                        </p>
                    ))}
                </div>
                {props.action !== 'view' &&
                    <div className="text-center" style={{position: 'absolute', width: '100%', top: '67%'}}>
                        <ButtonAction>
                            <button type="button" onClick={(e) => props.history.push(`/farm/${desc.id}/view`)} className="btn"><i style={{color: '#FFFF', width:'30px'}} className='fas fa-eye'></i></button>
                            <button type="button" onClick={(e) => props.history.push(`/farm/${desc.id}/edit`)} className="btn" ><i style={{color: '#FFFF', width:'30px'}} className='fas fa-edit'></i> </button>
                            <button type="button" className="btn" onClick={()=>props.onDelete(desc.id)} ><i style={{color: '#FFFF', width:'1px'}} className='fas fa-trash-alt'></i> </button>
                        </ButtonAction>

                    </div>
                }
            </Card> */}
        </div>

    ))
    
}
