import React, { useState, useEffect } from 'react'
import Header from '../../../Header/Header'
import Sidebar from '../../../Sidebar/Sidebar'
import Footer from '../../../Footer/Footer'
import Table from '../../../Table/Table'
import Thead from '../../../Table/Thead'
import Tbody from '../../../Table/Tbody'
import Tfoot from '../../../Table/Tfoot'
import StylePage from '../../../Style Page/StylePage'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import { search, changePage, get, order, change_qtd_per_page, changePesquisa, clearPesquisa } from './Actions/actions'
import { pagination } from '../../../Pagination/paginations'
import {Link} from 'react-router-dom'
import Pagination from '../../../Table/Pagination'
import Cabecalho from '../../../Table/Cabecalho'
import Alert from '../../../Alerts/Alert'
import MyModal from '../../../Modal/MyModal'
import ReactLoading from 'react-loading'
import { Content } from '../../../LoadingStyle/StyleLoading'
import { changeStatus, del_action } from '../../../Services/action'
import Actions from './Actions'
import { ButtonContainner } from '../../../Button/style'


function GridAction(props) {
    const [ loading, setLoading ] = useState(false);
    const [ modal, setModal ] = useState(false);
    const [ id, setId ] = useState('');
    const [ success, setSuccess ] = useState('');
    const [ error, setError ] = useState('');
    const question = 'question'
    const title = 'title'
   
    const getTopics = () => {
        props.get();
    }

    useEffect(() => {
        getTopics();
        return () => {
            getTopics()
        }
        
    },[])

    
    const changeStatusActive = (event) =>{
        const id = event.target.id
        let checked = event.target.checked === true ? 1 : 0
        const data = {
            ativo:checked,     
        }
        changeStatus(id, data)  
        .then(resp => getTopics())
        .catch(resp => getTopics())
    }
     
    const onChangeModal = (id_prod) => {
        setModal(true);
        setId(id_prod);
        
    }

    const handleCloseExcluir = event => {
        setModal(false);
        setId('');
        
    }

    const handleExcluir = (e) => {
        e.preventDefault();
        setLoading(true);
        setModal(false)
        
        del_action(id)
        .then(resp => {
            setSuccess(resp.data.success)
            setError('')
            setLoading(false)
        
        })
        .then(resp => (
            setTimeout(()=> {
                setSuccess('')
            },4000)

        ))
        .catch(e => {
            setLoading(false)
            setError(e.response.data.error)
            setSuccess('')
            setModal(false)
            setTimeout(()=> {
                setError('')
            },4000)
        
        })
        .then(resp => (props.get()))
        // .then(resp => {setState({success:resp.data.success, error:''})})

    }

    return (
        <div className="wrapper">
            <Header />
            <Sidebar />
            <StylePage>

                {loading === true ? (
                    <Content>
                        <ReactLoading type="spinningBubbles" color="blue" height={200} width={100} />
                    </Content>
                    
                ):('')}
                {success ? (
                    <Alert type="success">{success}</Alert>
                ):("")}
                {error ? (
                    <Alert type="danger">{error}</Alert>
                ):("")}
                <MyModal
                    show={modal}
                    onHide={handleCloseExcluir}
                    title="Excluir Ação"
                    content="Tem certeza que deseja excluir a ação ?"
                    cancel={handleCloseExcluir}
                    del={handleExcluir}     
                    to="#/"
                    type="danger"
                    textButton="Excluir"                   
                />
                <div style={{display: loading === true ? ('none'):('block')}}>
                    <section className="content">
                        <section className="content-header">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-sm-6">
                                        <h2 style={{color: '#00ABFF'}}>Registros de Ações</h2>
                                    </div>
                                    <div className="text-right col-sm-6">
                                            <ButtonContainner>
                                                <button type="button" onClick={(e) => {props.history.push(`/action`);e.preventDefault()}} className="btn btn-primary">Cadastro</button>
                                            </ButtonContainner>
                                        {/* <Link to="/user" className="btn btn-primary">Cadastro</Link> */}
                                    </div>
                                </div>
                            </div>{/* /.container-fluid */}
                        </section>

                    
                    
                            {/* <div className="col-12"> */}
                            
                                <div className="card-body">
                                    {props.actions.length > 0 || props.pesquisa ?
                                        <Cabecalho to="#/" action={props.search} clear={props.clearPesquisa} valuePesquisa={props.pesquisa} value={props.qtd_per_page} onChange={props.change_qtd_per_page} changePesquisa={props.changePesquisa} />
                                        :''
                                    }   
                                    <div className="row mt-3">
                                        {
                                            props.actions.length == 0 ? 
                                                <div 
                                                    style={{
                                                        color: '#FFFF', 
                                                        textAlign: 'center', 
                                                        width: '100%'
                                                    }}
                                                >
                                                    <span>{`${!props.pesquisa ? 'Não existem Ações cadastradas no sistema. Clique no botão Cadastro para inserir uma Ação no Sistema' : 'Nenhum registro encontrado na pesquisa'}`}</span>
                                                </div>
                                            :''
                                        }
                                        <Actions history={props.history} changeStatus={changeStatusActive} actions={props.actions} onDelete={onChangeModal} />
                                    </div>
                                    {props.actions.length > 0 &&
                                        <div className="row">
                                            <div className="col-12 mt-4">
                                                <Pagination>{props.pagination(props.current_page,props.get,props.last_page,props.changePage)}</Pagination>
                                            </div>
                                        </div>
                                    }
                                </div>
                                
                            {/* </div> */}
                    
                    </section>

                    {/* <Table 
                        view={
                            <div className="col-sm-12 col-md-5">
                                <div className="dataTables_info" id="example1_info" role="status" aria-live="polite">
                                    Exibindo de {props.from} à {props.to} do total de {props.qtd_total}
                                </div>
                            </div>
                        }
                            
                        cabecalho={
                            
                            <Cabecalho to="#/" action={props.search} clear={props.clearPesquisa} valuePesquisa={props.pesquisa} value={props.qtd_per_page} onChange={props.change_qtd_per_page} changePesquisa={props.changePesquisa} />
   
                        }
                        pagination={<Pagination>{props.pagination(props.current_page,props.get,props.last_page,props.changePage)}</Pagination>} title="Registros de Ações"
                        button={
                            <ButtonContainner>
                                <button className="btn btn-primary" type="button" onClick={()=>props.history.push('/action')}>Cadastro</button>
                            </ButtonContainner>
                            
                            
                        }>
                        <Thead>
                            <th>Título<Link to="#/" style={{float:'right'}} onClick={()=>props.order(title)}><i className={`right fas fa-sort-amount-${props.th === 'title' ? (props.asc === true ?('down'):('up')):('down')}-alt`}></i></Link></th> 
                            <th>Pergunta<Link to="#/" style={{float:'right'}} onClick={()=>props.order(question)}><i className={`right fas fa-sort-amount-${props.th === 'question' ? (props.asc === true ?('down'):('up')):('down')}-alt`}></i></Link></th>
                            <th>Ações</th> 
                        </Thead>
                        <Tbody>
                            <Actions changeStatus={changeStatusActive} actions={props.actions} onDelete={onChangeModal} />
                        </Tbody>
                        <Tfoot> 
                            <th>Título</th>
                            <th>Pergunta</th>
                            <th>Ações</th> 
                        </Tfoot>
                    </Table>         */}
                </div>
            </StylePage>
            
            
            <Footer />
        </div>
    )
}

const mapStateToProps = state => ({
    actions:state.action.actions.data || [],
    current_page:state.action.actions.current_page,
    last_page:state.action.actions.last_page,
    from:state.action.actions.from,
    to:state.action.actions.to,
    qtd_total:state.action.actions.total,
    qtd_per_page:state.action.qtd_per_page,
    pesquisa:state.action.pesquisa,
    perfis:state.auth.user.perfis,
    id_role:state.auth.user.id_role,
    asc:state.action.asc,
    th:state.action.th  
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({
        pagination, 
        get, 
        order, 
        change_qtd_per_page, 
        changePesquisa, 
        clearPesquisa,
        changePage,
        search 
    }, dispatch);

export default connect(mapStateToProps,mapDispatchToProps)(GridAction)
