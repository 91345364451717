import React from 'react'
import { Link } from 'react-router-dom'
import { Container } from './Style'

export default function NavItem(props) {
    return (
        <React.Fragment>
            <li className="nav-item">
                <Container>

                    <Link to={props.to} className="nav-link">
                        <i  className={`nav-icon ${props.icon}`} />
                        <p>
                            {props.iconCircle?(
                                <i className={`${props.iconCircle}` || ''}></i>
                            ):('')}
                            {props.item}
                            
                        </p>
                    </Link>
                </Container>
            </li>
        </React.Fragment>
    )
}
