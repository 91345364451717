import styled from "styled-components";

export const ButtonContainner = styled.div`
    button{
        background: linear-gradient(180deg, #00E6FF 0%, #0070BA 109.18%);
        display: 'inline-block';
    }
`;

export const ButtonSaveContainner = styled.div`
    button{
        background: '#F2994A';

        /* background: linear-gradient(180deg, #00E6FF 0%, #0070BA 109.18%); */
        display: 'inline-block';
    }
`;

export const ButtonAction = styled.div`
    button{
        background: '#262D40';
        @media(min-with: 768px){
            display: 'block';
        }
        @media(max-with: 768px){
            display: 'block';
        }
    }
    button :hover{
        background: '#262D40';
    }
`;