import React, { useState, useEffect, useRef } from 'react'
import Header from '../../../Header/Header'
import Sidebar from '../../../Sidebar/Sidebar'
import StylePage from '../../../Style Page/StylePage'
import Form from '../../../Forms/Form'
import Alert from '../../../Alerts/Alert'
import Footer from '../../../Footer/Footer'
import ReactLoading from 'react-loading'
import Radio from '@material-ui/core/Radio';
import { Content } from '../../../LoadingStyle/StyleLoading'
import NumberFormat from 'react-number-format'
import { useHistory } from "react-router-dom"
import { postFarm, getViewFarm, putFarm, getResource, getFarms } from '../../../Services/farm'
import '../../../../Dependencies/dependencies'
import '../../../../Dependencies/jquery-loader'
import InputMaterial from '../../../Forms/InputMaterial';
import SelectMaterial from '../../../Forms/SelectMaterial';
import RadioMaterial from '../../../Forms/RadioMaterial';
import { ButtonContainner } from '../../../Button/style';
import Autocomplete from '@material-ui/lab/Autocomplete';
import makeAnimated from 'react-select/animated'
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import clsx from 'clsx';
import {
    makeStyles,
  } from '@material-ui/core/styles';
import { ListFlowPackFarm } from './ListFlowPackFarm'


export default function Farm(props) {

    let history = useHistory();
    const animatedComponents = makeAnimated();
    const selectLineRef = useRef(null)
    const [ display, setDisplay ] = useState('block')
    const [ loading, setLoading ] = useState(false)
    const [ code, setCode ] = useState('')
    const [ coordinate, setCoordinate ] = useState('')
    const [ name, setName ] = useState('')
    const [ landLine, setLandLine ] = useState('')
    const [ pesquisa, setPesquisa ] = useState('')
    const [ mobilePhone, setMobilePhone ] = useState('')
    const [ states, setStates ] = useState([])
    const [ idState, setIdState ] = useState([])
    const [ according, setAccording ] = useState("Não")
    const [ page, setPage ] = useState(1)
    const [ flowPacksFarms, setFlowPacksFarms ] = useState([])
    const [ qtdPerPage, setQtdPerPage ] = useState(10)
    const [ order, setOrder ] = useState('asc')
    const [ th, setTh ] = useState('farm.name')
    const [ lines, setLines ] = useState([])
    const [ idLine, setIdLine ] = useState([])
    const [ producers, setProducers ] = useState([{value: '', title: ''}])
    const [ idProducer, setIdProducer ] = useState({value: '', title: ''})
    const [ error, setError ] = useState([])
    const [ success, setSuccess ] = useState('')
    const [ alert, setAlert ] = useState('')
    
    useEffect(() => {
        getResourceAll()
       
    }, [])
    
    useEffect(() => {
        viewPerId()
       
    }, [props.match.params.id])

    useEffect(() => {
        getFlowPacksFarmsAll()
       
    }, [props.match.params.id])

    function getFlowPacksFarmsAll(){
        let idFarm = props.match.params.id
        props.match.params.action &&
        getFarms(page,qtdPerPage,order,th,pesquisa,idFarm)
        .then(resp => setFlowPacksFarms(resp.data.data[0].flowPacks))
    }


    const useStyles = makeStyles((theme) => ({
        root: {
            display: 'flex',
            flexWrap: 'wrap',
            
           
        },
        margin: {
            '& label.Mui-focused': {
                color: '#BEBEBE',
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: '#FFFF',
                color: '#FFFF'
            },
            '& .MuiInput-underline:before': {
                borderBottom: '1px solid rgba(255, 255, 255, 0.42)'
            },
            '& .MuiInputBase-input':{
                color: '#FFFF'
            },
            '& .MuiFormLabel-root':{
                
                color: '#BEBEBE',
                fontSize: '12px',
                marginBottom: '20px'
            },
            margin: theme.spacing(1),
            width: '100%'
        },
    }));

    const classes = useStyles();

    const useStylesRadio = makeStyles({
        root: {
            '&:hover': {
                backgroundColor: 'transparent',
            
            },
            
           
            color:'#FFFF'
            
        },
        icon: {
            borderRadius: '50%',
            width: 16,
            height: 16,
            boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
            backgroundColor: '#f5f8fa',
            backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
            '$root.Mui-focusVisible &': {
                outline: '2px auto rgba(19,124,189,.6)',
                outlineOffset: 2,
            },
            'input:hover ~ &': {
                backgroundColor: '#ebf1f5',
            },
            'input:disabled ~ &': {
                boxShadow: 'none',
                background: 'rgba(206,217,224,.5)',
                color: '#FFFF'
            },
        },
        checkedIcon: {
            backgroundColor: '#137cbd',
            backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
            '&:before': {
                display: 'block',
                width: 16,
                height: 16,
                backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
                content: '""',
            },
            'input:hover ~ &': {
                backgroundColor: '#106ba3',
            },
        },
      });
      
    // Inspired by blueprintjs
    function StyledRadio(props) {
        const classesRadio = useStylesRadio();
        return (
            <Radio
                className={classesRadio.root}
                disableRipple
                color="default"
                
                checkedIcon={<span className={clsx(classesRadio.icon, classesRadio.checkedIcon)} />}
                icon={<span className={classesRadio.icon} />}
                {...props}
            />
        );
      }

    function viewPerId(){
        if(props.match.params.action){
            getViewFarm(props.match.params.id)
            .then(resp => {
                setName(resp.data.farms.name)
                setCode(resp.data.farms.code)
                setLandLine(resp.data.farms.landLine)
                setMobilePhone(resp.data.farms.mobilePhone)
                setIdProducer({value: resp.data.farms.id_producer, title: resp.data.farms.producer})
                setIdState({value: resp.data.farms.id_state, title: resp.data.farms.state})
                setAccording(resp.data.farms.according_in7677 === 1 ? 'Sim' : 'Não')
                setIdLine(resp.data.lines.map(desc => ({
                    value: parseInt(desc.id),
                    title: desc.line 
                })))

            })
        }
        
    }

    function getResourceAll(){
        getResource()
        .then(resp => {
            setProducers(resp.data.producers.map(desc => ({
                value: parseInt(desc.id),
                title: desc.name
            })))
            setLines(resp.data.lines.map(desc => ({
                value: parseInt(desc.id),
                title: desc.name
            })))
            setStates(resp.data.states.map(desc => ({
                value: parseInt(desc.id),
                title: desc.name
            })))
        })
    }

    const changeName = event => {
        const name = event.target.value
        setName(name)
    }

    const changeLandLine = event => {
        const number = event.target.value.replace('(','').replace(')','').replace(' ','').replace('-','')
        setLandLine(number)
    }

    const changeMobilePhone = event => {
        const number = event.target.value.replace('(','').replace(')','').replace(' ','').replace('-','')
        setMobilePhone(number)
    }

    const defaultProps = {
        options: producers,
        getOptionLabel: (option) => option.title,
        value:(option) => option.value
    };
    const defaultPropsState = {
        options: states,
        getOptionLabel: (option) => option.title,
        value:(option) => option.value
    };

    const defaultPropsLine = {
        options: lines,
        getOptionLabel: (option) => option.title,
        value:(option) => option.value
    };

    const handleForm=(e)=>{
        e.preventDefault();
        setLoading(true)

        let newLines = [];
        idLine.map(desc => newLines.push(desc.value))
        
        const data = {
            name: name,
            code: parseInt(code),
            mobilePhone: mobilePhone,
            landLine: landLine,
            idProducer: idProducer.value,
            // lines: newLines,
            according: according === 'Sim' ? 1 : 0,
            idState: idState.value,
            coordinate: coordinate
        }

        if(props.match.params.action === 'edit'){
            const id = props.match.params.id
            putFarm(id,data)
            .then(resp=> {
                setLoading(false)
                setAlert('')
                setError([])
                setSuccess(resp.data.success)    
            })
            .then(resp => setTimeout(() => {
                window.location.reload()
            },2500))
            .catch(e => {
                setLoading(false)
                setSuccess('')
                setError(e.response.data.error)
                setAlert(e.response.data.errorSubmit)  
            })              


        }else{
            postFarm(data)
            .then(resp=> {
                setLoading(false)
                setAlert('')
                setError([])
                setSuccess(resp.data.success)    
            })
            .then(resp => setTimeout(() => {
                window.location.reload()
            },2500))
            .catch(e => {
                setLoading(false)
                setSuccess('')
                setError(e.response.data.error)
                setAlert(e.response.data.errorSubmit)  
            })              

        }
    }

    return (
        <div className="wrapper">
            <Sidebar />
            <Header />
            <StylePage>
                {loading === true ? (
                    <Content>
                        <ReactLoading type="spinningBubbles" color="blue" height={200} width={100} />
                    </Content>
                    
                ):('')}
                {alert ? (
                    <Alert type="danger">
                        {alert}
                    </Alert>
                ):('')}
                {success ? (
                    <Alert type="success">
                        {success}
                    </Alert>
                ):('')}
                <div style={{display: loading === true ? ('none'):('block')}}>
                    <section className="content-header">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <h2 style={{color: '#00ABFF', textAlign: 'center'}}>{props.match.params.action === 'edit' ? "Edição de Fazendas" : props.match.params.action === 'view' ? "Visualização de Fazendas" : "Cadastro de Fazendas"}</h2>
                                </div>
                            </div>
                        </div>{/* /.container-fluid */}
                    </section>
                    <Form onSubmit={handleForm} autoComplete="false">
                        <div className="row">
                            <div className="col-md-6">
                                <NumberFormat  
                                    label="Código" 
                                    name="codeFarm" 
                                    fixedDecimalScale={true}
                                    decimalScale={0}
                                    placeholder=""
                                    customInput={InputMaterial}
                                    allowNegative={false}
                                    onChange={(e)=>setCode(parseInt(e.target.value))} 
                                    value={code}
                                    disabled={props.match.params.action == 'view' ? true : false}
                                    required={true} 
                                    isAllowed={(values) => { const {floatValue,formattedValue} = values;  return formattedValue === "" || floatValue <= 99999999999;}}                         
                                />
                                {error ? (<p style={{color:'red'}}>{error.code}</p>):('')}
                            </div>
                            <div className="col-md-6">
                                <InputMaterial 
                                    label="Nome Completo" 
                                    value={name} 
                                    onChange={changeName} 
                                    id="nameFarm"
                                    name="nameFarm"
                                    type="text" 
                                    placeholder="Digite o nome da fazenda" 
                                    required={true}
                                    disabled={props.match.params.action === 'view' ? true : false}
                                />
                                 {error ? (<p style={{color:'red'}}>{error.name}</p>):('')}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <NumberFormat 
                                    disabled={props.match.params.action === 'view' ? (true):(false)} 
                                    allowEmptyFormatting={false} 
                                    label="Telefone Fixo"  
                                    mask='' 
                                    required={true}
                                    customInput={InputMaterial} 
                                    onChange={changeLandLine}  
                                    format={"(##) ####-####"} 
                                    value={landLine}
                                    name="landLineFarm" 
                                    id="landLineFarm"
                                    type="text" 
                                    placeholder="Digite o telefone fixo da fazenda" 
                                />
                                {error ? (<p style={{color:'red'}}>{error.landLine}</p>):('')} 
                            </div>
                            <div className="col-md-6">
                                <NumberFormat 
                                    disabled={props.match.params.action === 'view' ? (true):(false)} 
                                    allowEmptyFormatting={false} 
                                    label="Telefone Celular"  
                                    mask='' 
                                    customInput={InputMaterial} 
                                    onChange={changeMobilePhone}  
                                    format={"(##) #####-####"} 
                                    value={mobilePhone}
                                    name="mobilePhoneFarm" 
                                    id="mobilePhoneFarm"
                                    type="text" 
                                    placeholder="Digite o telefone celular da fazenda" 
                                />
                                {error ? (<p style={{color:'red'}}>{error.mobilePhone}</p>):('')} 
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <SelectMaterial 
                                    label="Produtor"
                                    id="idProducer"
                                    name="idProducer"
                                    value={idProducer}
                                    onChange={(event, newValue) => {
                                        setIdProducer(newValue);
                                    }}
                                    defaultProps={defaultProps}
                                    required={true}
                                    disabled={props.match.params.action === 'view' ? true : false}
                                />
                                {error ? (<p style={{color:'red'}}>{error.idProducer}</p>):('')} 
                            </div>
                            <div className="col-md-6">
                                <SelectMaterial 
                                    label="Estado"
                                    id="idState"
                                    name="idState"
                                    autoComplete="off"
                                    value={idState}
                                    onChange={(event, newValue) => {
                                        setIdState(newValue);
                                    }}
                                    defaultProps={defaultPropsState}
                                    required={true}
                                    disabled={props.match.params.action === 'view' ? true : false}
                                />
                                {error ? (<p style={{color:'red'}}>{error.idState}</p>):('')} 
                            </div>
                            
                        </div>
                        <div className="row">
                            {/* <div className="col-md-6">
                                <Autocomplete
                                    {...defaultPropsLine}
                                    id="linesFarm" 
                                    name="linesFarm"
                                    value={idLine}
                                    multiple
                                    ref={selectLineRef}
                                    onChange={(event, newValue) => {
                                        let value = newValue
                                        // console.log(value)
                                        for(let i = 0; i < value.length; i++){
                                            for(let j = i+1; j < value.length; j++){
                                                if(parseInt(value[j].value) === parseInt(value[i].value)){
                                                    value.splice(i,1)
                                                    value.splice(j,1)
                                                }
                                            }
                                        }
                                        // console.log(value)

                                        setIdLine(value);
                                    }}
                                    required={true}
                                    disabled={props.match.params.action === 'view' ? true : false}
                                    renderInput={(params) => <TextField {...params}  className={classes.margin} label="Linhas *"  />}
                                />
                                {idLine ? idLine.length <= 0 ? (

                                    <input
                                        tabIndex={-1}
                                        autoComplete="off"
                                        style={{
                                            opacity: 0,
                                            width: "100%",
                                            height: 0,
                                            position: "absolute"
                                        }}
                                        defaultValue={idLine}
                                        onFocus={() => selectLineRef.current.focus()}
                                        required={true}
                                    />
                                    ):''
                                    : idLine == null ? (
                                    <input
                                        tabIndex={-1}
                                        autoComplete="off"
                                        style={{
                                            opacity: 0,
                                            width: "100%",
                                            height: 0,
                                            position: "absolute"
                                        }}
                                        defaultValue={idLine}
                                        onFocus={() => selectLineRef.current.focus()}
                                        required={true}
                                    />
                                    ):''

                                }
                               
                            </div> */}
                            <div className="col-md-12">
                                <InputMaterial 
                                    label="Coordenadas" 
                                    value={coordinate} 
                                    onChange={(e)=>setCoordinate(e.target.value)} 
                                    id="nameCoordinate"
                                    name="nameCoordinate"
                                    type="text" 
                                    placeholder="Digite a coordenada" 
                                    required={false}
                                    disabled={props.match.params.action === 'view' ? true : false}
                                />
                                 {error ? (<p style={{color:'red'}}>{error.coordinate}</p>):('')}
                            </div>
                        </div>
                        <div className="row mt-4 ml-2">
                            <div className="col-md-6">
                                <RadioMaterial
                                    row 
                                    label="Conforme IN76/77 ?"
                                    ariaLabel="rightAnswer" 
                                    name="rightAnswer" 
                                    value={according}
                                    styleLabel={{
                                        color: '#BEBEBE',
                                        fontSize: '12px',
                                        marginBottom: '15px'
                                    }} 
                                    onChange={(e)=>setAccording(e.target.value)}
                                    disabled={props.match.params.action === 'view' ? true : false}
                                >
                                    <FormControlLabel value="Sim" control={<StyledRadio />} label="Sim" />
                                    <FormControlLabel value="Não" control={<StyledRadio />} label="Não" />
                                </RadioMaterial>
                            </div>
                            
                        </div>
                        <br></br>
                        <div className="text-right">
                            <button style={{marginRight:'10px'}} type="button" className="btn btn-secondary" onClick={()=>history.goBack()}>Voltar</button>
                            {props.match.params.action === 'view' ? (
                                 <div style={{display: 'inline-block'}}>
                                    <ButtonContainner>
                                        <button onClick={(e) => {props.history.push(`/farm/${props.match.params.id}/edit`);e.preventDefault()}} className="btn btn-primary">Editar</button>
                                    </ButtonContainner>
                                </div>
                            ) : (
                                <div style={{display: 'inline-block'}}>
                                    <ButtonContainner>
                                        <button disabled={props.match.params.action === 'view' ? true : false} type="submit" className="btn btn-primary">Salvar</button>                           
                                    </ButtonContainner>
                                </div>
                            )}
                        </div>
                    </Form>
                </div>
                {props.match.params.action &&
                    <React.Fragment>

                    <div className="card" style={{background: 'linear-gradient(180deg,#1D3A59 0%,#0A1D3A 100%)', width: '100%'}}>
                        <a style={{background: 'linear-gradient(180deg,#1D3A59 0%,#0A1D3A 100%)', border: 0, color: '#FFFF'}} onClick={(e)=>setDisplay(`${display === 'none' ? 'block' : 'none'}`)} className="nav-link" className="btn btn-light">Pacotes de Fluxos  <i className={`fas fa-${display === 'none' ?('angle-double-down'):('angle-double-up')}`}></i></a>

                    </div>

                        <div style={{display: display}}>
                            <ListFlowPackFarm flowPacksFarms={flowPacksFarms} history={props.history} action={props.match.params.action} />
                        </div>
                    </React.Fragment>
                }   
            </StylePage>
            <Footer />
        </div>
    )
}
