import { app } from './Api'

const getViewLine = async (id) => {
    return app.get(`line/${id}`)
}

const getLines = async (page,qtd_per_page,order,th,pesquisa) => {
    return app.get(`line?page=${page}&qtd=${qtd_per_page}&order=${order}&th=${th}&pesquisa=${pesquisa}`)
}

const postLine = async (data) => {
    return app.post(`line`, data)
}

const changeStatus =  async (id,data) => {
    return app.put(`change_status_line/${id}`,data)
}

const putLine = async (id,data) => {
    return app.put(`line/${id}`,data)
}

const delLine = async (id) => {
    return app.delete(`line/${id}`)
}

const delFarmLine = async (idLine,idFarm) => {
    return app.delete(`del_farm_line?idFarm=${idFarm}&idLine=${idLine}`)
}


export { getViewLine, getLines, changeStatus, postLine, putLine, delLine, delFarmLine }